import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Backbone from 'backbone';

import { AppView } from '../oauth/components/AppView';
import { OAuthController } from '../oauth/controllers/OAuthController';

let OAuthRouter = Backbone.Router.extend({
  routes: {
    '': 'oauth', // Main entry point for OAuth
    login: 'login',
    'forgot-password': 'forgot-password',
    authorize: 'authorize',
    '*error': 'error', // This handles all invalid routes.
  },

  errorEvent: null,

  rooms: function() {
    new OAuthController(this).viewRooms();
  },

  oauth: function() {
    let state = this.getParameterByName('state');
    let redirectUri = this.getParameterByName('redirect_uri');
    let clientId = this.getParameterByName('client_id');
    let responseType = this.getParameterByName('response_type');

    new OAuthController(this).viewOAuthLogin(
      clientId,
      state,
      redirectUri,
      responseType
    );
  },

  login: function() {
    new OAuthController(this).viewOAuthLogin();
  },

  'forgot-password': function() {
    new OAuthController(this).viewOAuthForgotPassword();
  },

  authorize: function() {
    new OAuthController(this).viewOAuthAuthorize();
  },

  error: function() {
    let error = this.errorEvent;
    this.errorEvent = null;

    new OAuthController(this).viewErrorRoute(error);
  },

  getParameterByName: function(name, url) {
    if (!url) url = window.location.href;

    name = name.replace(/[\[\]]/g, '\\$&');

    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
});

ReactDOM.render(<AppView />, document.getElementById('content'));

let router = new OAuthRouter();
module.exports = router;
