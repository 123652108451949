/*
 This module represents the room to which the user is currently listening (via pubnub).

 If the user is a:

   free teacher - this represents the one and only (default) room
   premium teacher - this represents the room currently selected in the header
   student - this represents the currently joined room
*/

import { initializeAmplitude } from '~analytics/amplitude';

import { currentRoomChanged } from '~teacher/slices/teacher/teacherSlice';

import reduxStore from '../../clients/app/src/store';
import renewalModel from '../../clients/app/teacher/renewal/RenewalModel';
import AccountModel from './AccountModel';

const Backbone = require('backbone');
const _ = require('underscore');
const request = require('../Request');

const IntercomService = require('../../clients/app/intercom/IntercomService');

const Room = Backbone.Model.extend({
  initialize: function () {
    this.set('joining', true);
    this.set('joinCallbacks', []);
    this.set('name', '');
    this.set('studentCount', 0);
    this.on('change:id', this.reduxCurrentRoomMapper);
  },

  reduxCurrentRoomMapper: function (model, value) {
    const roomId = model.get('id');
    const isShowbieRoom = model.isShowbieClass();
    reduxStore.dispatch(currentRoomChanged({ roomId, isShowbieRoom }));
  },

  setRoomName: function (value) {
    room.set('name', value);
  },

  getRoomName: function () {
    return room.get('name');
  },

  getRoomId: function () {
    return room.get('id');
  },

  getRooms: function () {
    return room.get('rooms');
  },

  hasRoster: function () {
    return room.get('rostered');
  },

  isShowbieClass: function () {
    return !!room.get('showbie_class_id');
  },

  getRosterSize: function (roomName) {
    let rosterSize = 0;
    if (room.get('rooms')) {
      const rooms = room.get('rooms');
      for (let i = 0; i < rooms.length; i++) {
        const currentRoom = rooms[i];
        if (currentRoom.name === roomName) {
          rosterSize = currentRoom.student_count;
          break;
        }
      }
    }
    return rosterSize;
  },

  getStudents: function () {
    return room.get('students');
  },

  join: function (options) {
    const requestData = {
      role: options.role, // The role option must be set to either 'student' or 'teacher'.
    };

    if (options.roomname) {
      requestData.name = options.roomname || '';
    }

    requestData.tz_offset = new Date().getTimezoneOffset();

    if (options.auth_token) {
      requestData.auth_token = options.auth_token; // This is necessary for MC SSO.
    }

    let url =
      options.useIntegrationRoute && options.roomId
        ? `${window.teacher_service}/student/rooms/${options.roomId}/join/`
        : `${window.backend_host}/rooms/api/join/`;

    request.post({
      url: url,
      data: requestData,
      success: (data) => {
        room.clear().set(data);

        let user = require('user');
        user.setDataFromRoom(data);

        if (data.notifications) {
          renewalModel.setData(data.notifications);
        }

        let roomName = data.name.toLowerCase();

        let messaging = require('Messaging');

        if (options.setupAnalytics) {
          let accountModel = new AccountModel();
          accountModel.fetchAccount(
            (data) => {
              user.setHmac(accountModel.hmacToken);
              user.setBetaFlags(accountModel.betaFlags);
              user.setCountry(accountModel.country);
              user.setEmail(accountModel.email);

              initializeAmplitude(data.amplitudeData);
              if (options.amplitudeEvent) {
                options.amplitudeEvent();
              }

              IntercomService.initialize(user);
              IntercomService.boot();
              console.log('Intercom booted');
            },
            () => {
              console.error('Intercom failed');
            }
          );
        }

        if (options.skipPubnub !== true && !options.studentLogin) {
          messaging.subscribeToMessages({
            channel: `${roomName}-${user.getMode()}`,
            user: user,
          });
        }

        if (options.skipPubnub !== true && options.role === 'teacher') {
          messaging.subscribeToPresence({
            channel: `${roomName}-student`,
            user: user,
          });
        }

        if (_.isFunction(options.success)) {
          options.success(data);
        }
        _.each(room.get('joinCallbacks'), function (callback) {
          callback();
        });
      },
      error: (response) => {
        if (_.isFunction(options.error)) {
          options.error(response);
        }
      },
      complete: () => {
        room.set('joining', false);
        room.set('joinCallbacks', []);
      },
    });
  },

  getRoomsList: function (options) {
    request.get({
      url: `${window.backend_host}/rooms/api/list/?in_menu=${
        options.inMenu || false
      }`,
      success: function (data) {
        if ('rooms' in data) {
          let rooms = _.sortBy(data.rooms, 'name');
          room.set('rooms', rooms);
          if (options.success) {
            options.success(data);
          }
        } else {
          if (options.error) {
            options.error(data);
          }
        }
      },
      error: function (xhr) {
        if (options.error) {
          options.error(xhr);
        }
      },
    });
  },

  getStudentList: function (options) {
    if (!options.roomName) {
      options.roomName = room.getRoomName();
    }

    let url = window.is_integration
      ? `${window.teacher_service}/integration/rooms/${room.get(
          'id'
        )}/students/`
      : `${window.backend_host}/rooms/api/${options.roomName}/students/`;
    request.get({
      url: url,
      success: (students) => {
        /**
         * students is an array of student objects:
         *     {
         *         first_name: 'Alice',
         *         handraise: false,
         *         id: 22,
         *         last_name: 'Smith',
         *         logged_in: true,
         *         student_id: '44',
         *         sync_id: null,
         *         user_uuid: ''
         *     }
         */

        // Only store the student list on this model if it's for the current room.
        if (options.roomName === room.getRoomName()) {
          room.set(
            'students',
            _.sortBy(students, (student) => {
              return student.last_name + ', ' + student.first_name;
            })
          );
        }

        options.success(students);
      },
      error: (data) => {
        if (options.error) {
          options.error(data);
        }
      },
    });
  },

  updateStudentHandraise: function (id, value, callback) {
    if (id) {
      const list = room.get('students');
      const student = _.find(list, function (item) {
        return item.id === id;
      });
      if (_.isBoolean(value)) {
        student.handraise = value;
      } else {
        if (value === 'off') {
          student.handraise = false;
        } else {
          student.handraise = true;
        }
      }
    }

    room.setHandsRaised();

    if (callback) {
      callback();
    }
  },

  clearAllHandraises: function () {
    const list = room.get('students');
    _.each(list, function (item) {
      item.handraise = false;
    });

    room.setHandsRaised('clearAll');
  },

  setHandsRaised: function (value) {
    if (room) {
      const list = room.get('students');
      let i = 0;

      if (value === 'clearAll') {
        this.set('handsRaised', i);
        return;
      }

      _.each(list, function (item) {
        if (item.handraise) {
          i++;
        }
      });

      this.set('handsRaised', i);
    }
  },

  getHandsRaised: function () {
    return this.get('handsRaised');
  },
});

let room = new Room();
module.exports = room;
