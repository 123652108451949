import * as React from 'react';

export default class ActivityIcon extends React.Component {
  render() {
    let { className } = this.props;

    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
      >
        <path d="M13.624 21.652c5.272-5.272 5.272-13.82 0-19.091L14.685 1.5l.018.018c5.84 5.86 5.834 15.343-.018 21.195l-1.06-1.06z" />
        <path d="M11.857 19.885c4.295-4.296 4.295-11.26 0-15.557L10.796 5.39a9.5 9.5 0 0 1 0 13.435l1.06 1.06z" />
        <path d="M9.028 17.056l-1.06-1.06a5.5 5.5 0 0 0 0-7.779l1.06-1.06a7 7 0 0 1 0 9.9zM5.5 13.586a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
      </svg>
    );
  }
}
