import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Backbone from 'backbone';

import { ActivityDoneView } from '../integrations/components/ActivityDoneView';
import { AppView } from '../integrations/components/AppView';
import { ErrorView } from '../integrations/components/ErrorView';
import { GatekeeperController } from '../integrations/controllers/GatekeeperController';
import { LaunchController } from '../integrations/controllers/LaunchController';
import { RoomsController } from '../integrations/controllers/RoomsController';
import * as MessengerTypes from '../integrations/shared/messenger/MessengerTypes';
import { PostMessenger } from '../integrations/shared/messenger/PostMessenger';
import { dynamicImport } from '../src/util/dynamicImport';

const request = require('../../../shared/Request');

const activityResultController = dynamicImport({
  moduleImport: () =>
    import(
      /* 'ActivityResultController' */ '../src/teacher/controllers/ActivityResultController' // Should be '~teacher/controllers/ActivityResultController' but for some reason that alias wouldn't work -RP
    ),
});

let IntegrationsRouter = Backbone.Router.extend({
  routes: {
    '': 'gatekeeper',
    rooms: 'rooms',
    launch: 'launch',
    results: 'resultsEntry',
    'results(/:activityId)': 'activityResult',
    'results/:activityId/questions/:questionPosition': 'activityResultQuestion',
    'results/done': 'doneResults',
    error: 'error',
  },

  allowedParams: ['roomId', 'destination', 'activityInstanceId'],

  errorEvent: null,
  activityInstanceId: null,

  gatekeeper: function () {
    let token = this.getParameterByName('token');
    let params = this.filterAllowedParams();
    new GatekeeperController(this).view(token, params);
  },

  rooms: function () {
    new RoomsController(this).viewRooms();
  },

  launch: function () {
    let roomId = this.getParameterByName('roomId');
    if (!roomId) {
      this.error();
    } else {
      let url = `${window.teacher_service}/integration/rooms/${roomId}`;
      request.get({
        url: url,
        success: (response) => new LaunchController(this).viewPrompt(response),
        error: (response) => this.error(response),
      });
    }
  },

  resultsEntry: function () {
    let roomId = this.getParameterByName('roomId');
    this.activityInstanceId = this.getParameterByName('activityInstanceId');

    if (!roomId || !this.activityInstanceId) {
      this.error();
    } else {
      let url = `${window.teacher_service}/integration/rooms/${roomId}`;
      request.get({
        url: url,
        success: () => {
          // Ensure inputs are numbers
          let activityInstanceId = parseInt(this.activityInstanceId);
          activityResultController().then((activityResultController) => {
            activityResultController.doActivityResult({
              activityInstanceId,
            });
          });
        },
        error: (response) => this.error(response),
      });
    }
  },

  activityResult: function (activityInstanceId) {
    // Ensure inputs are numbers
    activityInstanceId = parseInt(activityInstanceId);

    activityResultController().then((activityResultController) => {
      activityResultController.doActivityResult({
        activityInstanceId,
      });
    });
  },

  activityResultQuestion: function (activityInstanceId, questionPosition) {
    // Ensure activityInstanceId is a number
    activityInstanceId = parseInt(activityInstanceId);

    activityResultController().then((activityResultController) => {
      activityResultController.doActivityResultQuestion({
        activityInstanceId,
        questionPosition,
      });
    });
  },

  // TODO-FUTURE Move this out of the router and pass the messenger into the appropriate view?
  notifyFinish: function (activityInstanceId) {
    PostMessenger.sendMessage(MessengerTypes.QUIZ.FINISHED, {
      activityInstanceId: activityInstanceId,
    });
  },

  doneResults: function () {
    ReactDOM.render(
      <ActivityDoneView />,
      document.getElementById('main-content')
    );
  },

  error: function () {
    let error = this.errorEvent;
    this.errorEvent = null;

    ReactDOM.render(
      <ErrorView error={error} />,
      document.getElementById('main-content')
    );
  },

  routeToResults: function (activityInstanceId) {
    router.navigate(`results/${activityInstanceId}`, { trigger: true });
  },

  routeToResultsQuestion: function (
    activityInstanceId,
    questionPosition,
    shouldReplace = false
  ) {
    router.navigate(
      `results/${activityInstanceId}/questions/${questionPosition}`,
      {
        trigger: true,
        replace: shouldReplace,
      }
    );
  },

  getParameterByName: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  filterAllowedParams: function () {
    let params = {};
    for (let item of this.allowedParams) {
      let param = this.getParameterByName(item);
      if (param) {
        params[item] = param;
      }
    }

    return params;
  },
});

ReactDOM.render(<AppView />, document.getElementById('content'));

let router = new IntegrationsRouter();

if (window.is_integration) {
  window.SOCRATIVE.teacherRouter = router;
}
module.exports = router;
