import * as React from 'react';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

import ActivityIcon from '../../../../shared/icons/ActivityIcon';
import LinkIcon from '../../../../shared/icons/LinkIcon';
import RosterIcon from '../../../../shared/icons/RosterIcon';

const Truncate = ({ children }) => (
  <div
    style={{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }}
  >
    {children}
  </div>
);

const RoomDetails = ({ room }) => {
  if (room.isDefault) {
    return translate('Default Room');
  } else if (room.isRostered && !room.showbieClassId) {
    return translate('Rostered Room');
  } else if (room.showbieClassId) {
    return (
      <Truncate>
        {translate('Connected to {0}').format(room.showbieClassName)}
      </Truncate>
    );
  } else {
    return translate('Not Connected');
  }
};

const HasActivity = ({ isActive }) => (
  <div className="room-cell__icon">
    <ActivityIcon className={isActive ? null : 'is-inactive'} />
  </div>
);

const HasRoster = ({ isRostered, isConnected }) => {
  return isRostered && !isConnected ? (
    <div className="room-cell__icon">
      <RosterIcon />
    </div>
  ) : null;
};

const HasConnection = ({ isConnected }) => {
  return isConnected ? (
    <div className="room-cell__icon">
      <LinkIcon />
    </div>
  ) : null;
};

export default class RoomItem extends React.Component {
  constructor() {
    super();
    this.handleRoomSelect = this.handleRoomSelect.bind(this);
  }

  render() {
    let { room } = this.props;

    return (
      <div className="room-cell">
        <button
          className={`room-cell__action ${
            room.isDefault ? 'is-disabled' : null
          }`}
          type="button"
          onClick={this.handleRoomSelect}
        >
          <div className="room-cell__content">
            <div
              className="room-cell__main"
              style={{ minWidth: 0 /* Required for truncation to work */ }}
            >
              <div className="room-cell__title">{room.name}</div>
              <div className="room-cell__details">
                <RoomDetails room={room} />
              </div>
            </div>
            <div className="room-cell__indicators">
              <HasRoster
                isRostered={room.isRostered}
                isConnected={room.showbieClassId}
              />
              <HasConnection isConnected={room.showbieClassId} />
              <HasActivity isActive={room.hasActivityInProgress} />
            </div>
          </div>
        </button>
      </div>
    );
  }

  handleRoomSelect() {
    this.props.onRoomSelect(this.props.room);
  }
}
