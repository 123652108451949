import * as React from 'react';

export default class Spinner extends React.Component {
  // constructor () {
  //   super()
  // }

  render() {
    const spinnerClass =
      this.props.size === 'small'
        ? 'ui-spinner ui-spinner--small'
        : 'ui-spinner';
    return <div className={spinnerClass} />;
  }
}
