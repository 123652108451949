import * as React from 'react';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

import * as MessengerTypes from '../shared/messenger/MessengerTypes';
import { PostMessenger } from '../shared/messenger/PostMessenger';
import RoomItem from './room-list/RoomItem';

const request = require('../../../../shared/Request');
const Constants = require('Constants');

export class RoomsView extends React.Component {
  constructor() {
    super();
    this.state = { loading: true, rooms: [], selectedRoom: null };
    this.onRoomsSuccess = this.onRoomsSuccess.bind(this);
    this.handleRoomSelect = this.handleRoomSelect.bind(this);
  }

  componentDidMount() {
    this.getRooms();
  }

  render() {
    if (this.state.loading) {
      /*
       * Removing Spinner since it conflicts with client loading
       * indicators. Leaving structure markup here in case some kind
       * of interstitial content is shown in the future.
       */
      return (
        <div className="content-container">
          <div className="content-main" />
        </div>
      );
    }

    let roomItems = this.state.rooms.map((room) => (
      <RoomItem
        key={room.id}
        room={room}
        onRoomSelect={this.handleRoomSelect}
      />
    ));

    return (
      <div className="content-container">
        <div className="content-header">
          <h1
            style={{
              flex: '1 1 auto',
              marginTop: '1rem',
              fontWeight: '500',
              textAlign: 'center',
            }}
          >
            {translate('Choose a Socrative room to connect.')}
          </h1>
        </div>
        <div className="content-main room-list">
          <div className="room-list__scroll-container">{roomItems}</div>
        </div>
      </div>
    );
  }

  getRooms() {
    let url = `${window.teacher_service}/integration/rooms`;

    request.get({
      url: url,
      success: this.onRoomsSuccess,
      error: (response) => {
        console.error(response);
        this.props.router.errorEvent = response;
        this.props.router.navigate('error', { trigger: true });
      },
    });
  }

  onRoomsSuccess(response) {
    this.setState({ rooms: response, loading: false });
  }

  handleRoomSelect(selectedRoom) {
    if (selectedRoom) {
      PostMessenger.sendMessage(MessengerTypes.ROOMS.SELECT, selectedRoom);
    }
  }
}
