class IntercomService {
  initialize(user) {
    let APP_ID = window.intercom_app_id;

    window.intercomSettings = {
      app_id: APP_ID,
      user_id: user.getId(),
      user_hash: user.getHmac(),
      custom_launcher_selector: '#contact-intercom-support',
      hide_default_launcher: window.innerWidth < 959, // hides the intercom launcher at the same point we show the hamburger
    };
  }

  boot() {
    if (window.Intercom) {
      window.Intercom('boot', window.intercomSettings);
    }
  }

  shutdown() {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }

  event(eventName) {
    if (window.Intercom) {
      window.Intercom('trackEvent', eventName);
    }
  }

  update() {
    if (window.Intercom) {
      window.Intercom('update', { 'App Version': window.app_version });
    }
  }
}

module.exports = new IntercomService();
