/**
 * TeacherModel - A global model for storing and accessing teacher data.
 *
 * The primary purpose of this model is to reduce API requests by storing response data
 * for the duration of the teacher's session, and keeping this data updated as needed.
 *
 * For example, instead of sending an HTTP GET for a teacher's quizzes every time he
 * visits the Quizzes tab, we reqeust the quizzes the first time they're needed and
 * store them on this model. If the teacher changes a quiz in some way (e.g. renames
 * it, or moves it to another folder), we don't request his quizzes again. Instead,
 * we simply update this model accordingly.
 */

'use strict';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

const request = require('../Request');
const Constants = require('../Constants');
const AccountModel = require('./AccountModel');
const FolderNavModel = require('./FolderNavModel');

class TeacherModel {
  constructor() {
    /* ------------ */
    /*    Account   */
    /* ------------ */
    this.account = new AccountModel();

    /* ------------ */
    /*    Quizzes   */
    /* ------------ */
    this.currentQuizFolderId = Constants.ROOT_FOLDER; // The id of the currently selected quiz folder.
    this.quizFolderNav = null; // An instance of FolderNavModel that represents the teacher's quiz folders as a tree data structure.
    this.quizzes = []; // The list of the teacher's quizzes, fetched from GET /v3/quizzes/.
    this.shouldFetchQuizzes = true; // Whether the quizzes should be fetched from GET /v3/quizzes/ when the teacher opens the launch quiz modal or navigates to the quizzes tab.
    this.showQuizSaved = false; // Whether the banner should show that a quiz was saved (set by edit-quiz-controller).
    this.showSharedImportFailure = false; // Whether the banner should show that a shared quiz failed to import (set by QuizzesController).
    this.showSharedImportSuccess = false; // Whether the banner should show that a shared quiz imported successfully (set by QuizzesController).

    /* ---------- */
    /*    Rooms   */
    /* ---------- */
    this.rooms = []; // The teacher's rooms.

    /* ------------ */
    /*    Rosters   */
    /* ------------ */
    this.rosterExportHeader = null; // Whether the first line of an exported roster should be a header.
    this.rosterExportOrder = null; // The column order in exported rosters.
    this.rosterExportType = null; // The roster export type.
    this.rosterImportHeader = null; // Whether the first line of the roster import file is a header.
    this.rosterImportOrder = null; // The column order in imported rosters.
    this.rosterImportType = null; // The roster import type.
    this.rosterSettingsFetched = false; // Whether the teacher's roster settings have been fetched.
    this.rosterSettingsId = 0; // The teacher's roster settings id.
  }

  /* -------------------- */
  /*    Client Requests   */
  /* -------------------- */

  fetchQuizzes(callback) {
    this.quizzes = [];

    request.get({
      url: `${window.teacher_service}/v3/quizzes/`,
      success: (data) => {
        if (data.folders) {
          for (let folder of data.folders) {
            folder.date = new Date(folder.lastUpdated);
          }

          if (!this.quizFolderNav) {
            this.quizFolderNav = new FolderNavModel(
              translate('Quizzes'),
              data.folders
            );
          }
        }

        if (data.quizzes) {
          this.quizzes = data.quizzes;
        }

        for (let quiz of this.quizzes) {
          quiz.date = new Date(quiz.date);
        }

        callback();
      },
      error: () => {
        callback();
      },
    });
  }
}

module.exports = new TeacherModel();
