import * as React from 'react';

export default class InfoIcon extends React.Component {
  render() {
    let color = this.props.color || '#ffffff';

    return React.createElement('svg', {
      className: this.props.className,
      width: '20px',
      height: '20px',
      viewBox: '0 0 20 20',
      dangerouslySetInnerHTML: {
        __html:
          '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-13.000000, -22.000000)" fill="' +
          color +
          '"><g transform="translate(13.000000, 22.000000)"><path d="M10,18 L10,18 C14.418278,18 18,14.418278 18,10 C18,5.581722 14.418278,2 10,2 C5.581722,2 2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 L10,18 Z M0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 Z"></path><path d="M11,14 L11,9 C11,8.44771525 10.5522847,8 10,8 C9.44771525,8 9,8.44771525 9,9 L9,14 C9,14.5522847 9.44771525,15 10,15 C10.5522847,15 11,14.5522847 11,14 L11,14 Z"></path><circle cx="10" cy="5.2" r="1"></circle></g></g></g>',
      },
    });
  }
}
