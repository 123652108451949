const React = require('react');
const ReactDOM = require('react-dom');

import { ConnectionStatusView } from '../clients/app/shared/connection-status/ConnectionStatusView';
/**
 * Socrative Messaging
 *
 * A pubnub wrapper that executes listener callbacks when messages are received or presence changes.
 *
 * The following object is an example of how the listeners are represented:
 *
 * {
 *     studentResponse: [ // message type
 *         callback1,
 *         callback2,
 *         ...
 *     ],
 *     presence: [ // message type
 *         callback3,
 *         callback4,
 *         ...
 *     ],
 *     ...
 * }
 *
 */

const request = require('../shared/Request');
const Constants = require('Constants');
const room = require('room');

const PubNubDown = 'PNNetworkDownCategory';
const PubNubUp = 'PNNetworkUpCategory';
const PubNubConnected = 'PNConnectedCategory';
const PubNubReconnected = 'PNReconnectedCategory';

const listeners = {};
/**
 * Listeners in this file and pubnub's listeners are different sets of listeners. The listeners
 * that pubnub track are presence and messages. We need an exact reference to each of the listeners
 * when we call window.pubnub.addListener so we can remove them properly using window.pubnub.removeListener
 * since pubnub's native removeListener does not do a deep comparison of objects
 */
let localCopyOfPubNubListeners = {};

/**
 * Private function called every time a message is received from pubnub. Executes all callbacks listening for the message.
 * @param {object} message The message received from pubnub
 */
const messageReceived = (message) => {
  const callbacks = listeners[message.key];

  if (callbacks) {
    for (const callback of callbacks) {
      callback(message.data);
    }
  }
};

/**
 * Private function called every time the presence changes. Executes all callbacks listening for presence.
 * @param {object} data The presence data received from pubnub
 */
const presenceChanged = (data, channelName) => {
  const callbacks = listeners[Constants.PRESENCE];
  let channelParts = channelName ? channelName.split('-') : [];
  let roomName = channelParts.length >= 2 ? channelParts[0] : null;

  if (callbacks) {
    if (room.hasRoster()) {
      room.getStudentList({
        success: () => {
          for (const callback of callbacks) {
            callback(data, roomName);
          }
        },
      });
    } else {
      for (const callback of callbacks) {
        callback(data, roomName);
      }
    }
  }
};

class Messaging {
  constructor() {
    this.showStatus = false;
    this.onDismissStatus = this.onDismissStatus.bind(this);
  }

  onDismissStatus() {
    this.showStatus = false;
    ReactDOM.render(
      <ConnectionStatusView showStatus={this.showStatus} />,
      document.getElementById('status-container')
    );
  }

  pubnubMessageListener(data) {
    let message = data.message;
    try {
      message = JSON.parse(data.message);
    } catch (error) {
      console.log('Failed to parse JSON message, defaulting to string.');
    }

    if (message.long_message_id) {
      // The message was too long to send via pubnub, so get it from the back end.
      request.get({
        url: `${window.backend_host}/socrative-tornado/api/long-message/${message.long_message_id}`,
        success: (response) => {
          message = response.message ? JSON.parse(response.message) : response;
          messageReceived(message);
        },
      });
    } else {
      messageReceived(message);
    }
  }
  /**
   * Build reconnection logic function to pass into listener options when we subscribe.
   * Some events of the status differ based on whether we are tracking presence or messages.
   * @param {object} props object with properties needed for the reconnection logic
   * @param {object} subscribeOptions object with channels and other attributes used for resubscribing
   * @param {boolean} isSubscribeToPresence boolean based on which subscribe method was called
   */
  createPubNubStatusListener(props, subscribeOptions, isSubscribeToPresence) {
    return (statusMessage) => {
      let category = statusMessage.category;

      if (category === PubNubConnected || category === PubNubReconnected) {
        console.log('PubNub connection established.');
        ReactDOM.render(
          <ConnectionStatusView
            showStatus={this.showStatus}
            user={props.user}
            onDismiss={this.onDismissStatus}
            connectionStatus="connected"
          />,
          document.getElementById('status-container')
        );

        if (isSubscribeToPresence) {
          // On reconnect, get the current student presence
          window.pubnub.hereNow(
            {
              channels: [props.channel],
            },
            (status, resp) => {
              if (!status.error && resp && resp.channels) {
                presenceChanged(resp.channels[props.channel], props.channel);
              }
            }
          );
        }
      }

      if (category === PubNubDown) {
        console.log('PubNub connection lost');
        this.showStatus = true;
        ReactDOM.render(
          <ConnectionStatusView
            showStatus={this.showStatus}
            user={props.user}
            connectionStatus="disconnected"
          />,
          document.getElementById('status-container')
        );
      }

      if (category === PubNubUp) {
        console.log('PubNub reconnecting...');
        ReactDOM.render(
          <ConnectionStatusView
            showStatus={this.showStatus}
            user={props.user}
            connectionStatus="reconnecting"
          />,
          document.getElementById('status-container')
        );
        window.pubnub.subscribe(subscribeOptions);
      }
    };
  }

  subscribeToMessages(props) {
    const subscribeOptions = {
      channels: [props.channel],
      restore: true,
    };
    localCopyOfPubNubListeners.messageListener = {
      message: this.pubnubMessageListener,
      status: this.createPubNubStatusListener(props, subscribeOptions, false),
    };

    window.pubnub.addListener(localCopyOfPubNubListeners.messageListener);
    window.pubnub.subscribe(subscribeOptions);
  }

  subscribeToPresence(props) {
    const subscribeOptions = {
      channels: [props.channel],
      restore: true,
      withPresence: true,
    };
    localCopyOfPubNubListeners.presenceListener = {
      presence: (data) => {
        presenceChanged(data, data.channel);
      },
      status: this.createPubNubStatusListener(props, subscribeOptions, true),
    };

    window.pubnub.addListener(localCopyOfPubNubListeners.presenceListener);
    window.pubnub.subscribe(subscribeOptions);
  }

  /**
   * Stop listening to a channel (both messages and presence).
   * @param {object} props An object with the following properties:
   *     {string|array} channel The channel name(s) (required)
   *     {function} callback Function to execute after unsubscribing (optional)
   */
  unsubscribe(props) {
    const { channel, callback } = props;

    window.pubnub.removeListener(localCopyOfPubNubListeners.messageListener);
    window.pubnub.removeListener(localCopyOfPubNubListeners.presenceListener);
    window.pubnub.unsubscribe({
      channels: Array.isArray(channel) ? channel : [channel],
    });

    if (callback) {
      callback();
    }
  }

  /**
   * Register a callback that will be executed each time a specific message is received from pubnub.
   * @param {object} props An object with the following properties (required):
   *     {string} message The message to listen for (use Constants.PRESENCE for presence)
   *     {function} callback A function to be executed each time the given message is received
   */
  addListener(props) {
    const { message, callback } = props;

    if (!listeners[message]) {
      listeners[message] = [callback];
    } else {
      listeners[message].push(callback);
    }
  }

  /**
   * Remove a function from the pool of callbacks.
   * @param {object} props An object with the following properties (required):
   *     {string} message The message to stop listening for (use Constants.PRESENCE for presence)
   *     {function} callback The function to remove from the pool of callbacks
   */
  removeListener(props) {
    const { message, callback } = props;
    const callbacks = listeners[message];

    if (callbacks) {
      for (let i = 0; i < callbacks.length; i++) {
        if (callbacks[i] === callback) {
          callbacks.splice(i, 1);
          return;
        }
      }
    }
  }
}

module.exports = new Messaging();
