import * as React from 'react';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

export class ActivityDoneView extends React.Component {
  render() {
    return (
      <div className="content-container">
        <div className="content-header">
          <h1>{translate('Activity Completing…')}</h1>
        </div>

        <div className="content-main"></div>
      </div>
    );
  }
}
