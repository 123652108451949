import { Messenger } from './Messenger';

export class PostMessenger extends Messenger {
  static sendMessage(type, payload, isError = false) {
    new PostMessenger().send(type, payload, isError);
  }

  static subscribeMessage(key, callback, isSingleUse = false) {
    new PostMessenger().subscribe(key, callback, isSingleUse);
  }
}
