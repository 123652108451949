import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { QueryClientProvider } from '@tanstack/react-query';
import { FinishActivityEventAdapter } from '~analytics/adapters/FinishActivityEventAdapter';
import { FinishRoute } from '~analytics/types';
import user from 'user';

// New stuff
import { LaunchDialog } from '~teacher/launchv2/LaunchDialog';
import { queryClient } from '~teacher/queryClient';
import teacher from 'TeacherModel';

import { finishActivity } from '../../src/api/activities/activityApi';
import { EndActivityPromptView } from '../components/EndActivityPromptView';
import * as MessengerTypes from '../shared/messenger/MessengerTypes';
import { PostMessenger } from '../shared/messenger/PostMessenger';

export class LaunchController {
  constructor(router) {
    this.router = router;
    // Set user to PRO for views to handle
    user.set('premium', true);
    user.set('mode', 'teacher');
  }

  viewPrompt(roomData) {
    if (roomData.hasActivityInProgress) {
      ReactDOM.render(
        <EndActivityPromptView
          room={roomData}
          continueClick={() => {
            finishActivity(roomData.activityInstanceId).then((results) => {
              // Analytics
              new FinishActivityEventAdapter({
                isRosteredRoom: results.data['isRostered'],
                settings: results.data['settings'],
                activityType: results.data['activityType'],
                numberStudents: results.data['students'].length,
                numberResponses: results.data['responses'].length,
                startTime: results.data['startTime'],
                endTime: results.data['endTime'],
                finishRoute: FinishRoute.StartNewActivity,
              }).sendAnalytics();
            });
            this.renderQuizView(roomData);
          }}
        />,
        document.getElementById('main-content')
      );
    } else {
      this.renderQuizView(roomData);
    }
  }

  renderQuizView(roomData) {
    const onClose = () => {
      PostMessenger.sendMessage(MessengerTypes.GENERAL.DISMISS, null);
    };

    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <LaunchDialog
          initialRoomName={roomData.name}
          initialRoomId={roomData.id}
          isRoomRostered={true}
          onClose={onClose}
          isOpen={true}
        />
      </QueryClientProvider>,
      document.getElementById('main-content')
    );
  }
}
