const localUser = require('../../../../shared/models/user');

let Backbone = require('backbone');
let _ = require('underscore');
let AnswerModel = require('./AnswerModel');
let MediaResourceModel = require('./MediaResourceModel');
let offlineStorage = require('offline-storage');
let request = require('../../../../shared/Request');
// TODO: this should not be here
Backbone.ajax = function (props) {
  props.stringify = false;
  return request.send(props.type, props);
};

// --------------
// structure
//
// "type": mc, tf, etc
// "order": 1,
// "quiz_id": 1,
// "answers": [],
// "explanation": "",
// "grading_weight": 1,
// "has_correct_answer": false,
// "question_text": "",
// "resources": [],

let LegacyQuestionModel = Backbone.Model.extend({
  urlRoot: function () {
    return window.backend_host + '/quizzes/api/questions/';
  },

  idAttribute: 'question_id',
  stringKeys: ['text', 'explanation', 'question_text'],

  initialize: function () {
    let that = this;

    if (this.has('answers')) {
      this.answers = [];

      let questionId = this.get('question_id');
      _.each(this.get('answers'), function (a) {
        that.addAnswer(new AnswerModel(a));
      });

      this.unset('answers');
    }

    if (this.has('resources')) {
      this.resources = [];

      let questionId = this.get('question_id');
      _.each(this.get('resources'), function (a) {
        let resource = new MediaResourceModel(a);

        resource.set('question_id', questionId);
        that.resources.push(resource);
      });

      this.unset('resources');
    }

    this.set('isTeacher', localUser.isTeacher());

    // make the question dirty false as the initial state
    // the question might be dirt: true because we use addAnswer earlier
    this.set('dirty', false);
  },

  nextAnswerOrder: function () {
    // Get the highest order in this collection, then add 1
    return (
      _.reduce(
        this.answers,
        function (memo, answer) {
          let thisOrder = answer.get('order');
          if (memo == null || thisOrder > memo) {
            return thisOrder;
          } else if (memo != null) {
            return memo;
          } else {
            return 0;
          }
        },
        null
      ) + 1
    );
  },

  addAnswer: function (answer) {
    // get the new order for the answer
    if (!answer.has('order')) {
      answer.set('order', this.nextAnswerOrder());
    }

    this.answers.push(answer);
    this.sortAnswers();

    // make the question dirty
    this.set('dirty', true);
  },

  removeAnswer: function (answer) {
    if (this.answers.length === 0) {
      return;
    }

    if (typeof answer === 'undefined') {
      answer = _.last(this.answers);
    }

    this.answers = _.filter(this.answers, function (a) {
      return a.cid !== answer.cid;
    });

    answer.off();

    // sort the answers
    this.sortAnswers();

    // and recreate the order if needed
    this.answers.forEach(function (ans, index) {
      ans.set('order', index + 1);
    });

    this.set('dirty', true);
  },

  // sort the answers
  sortAnswers: function () {
    this.answers = _.sortBy(this.answers, function (a) {
      return a.get('order');
    });
  },

  toJSON: function (includeChildren) {
    let json = Backbone.Model.prototype.toJSON.call(this);

    for (let key of this.stringKeys) {
      let value = json[key];
      if (value && value.length) {
        // Remove possible null characters
        json[key] = value.replace(/\0/g, '');
      }
    }

    if (_.isUndefined(includeChildren)) {
      if (!_.isUndefined(json.answers)) {
        delete json.answers;
      }
      if (!_.isUndefined(json.resources)) {
        delete json.resources;
      }
    } else if (includeChildren) {
      json.answers = [];
      json.resources = [];

      _.each(this.answers, function (a) {
        json.answers.push(a.toJSON(includeChildren));
      });

      _.each(this.resources, function (r) {
        json.resources.push(r.toJSON(includeChildren));
      });
    }

    return json;
  },

  save: function (attributes, options) {
    if (this.has('dirty') && this.get('dirty')) {
      this.unset('dirty');

      return offlineStorage.save(this, attributes, options);
    } else {
      return false;
    }
  },
});

module.exports = LegacyQuestionModel;
