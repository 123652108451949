import * as React from 'react';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

export class ErrorView extends React.Component {
  render() {
    let message = translate('An error has occurred');
    if (this.props.error) {
      if (typeof this.props.error === 'string') {
        message = this.props.error;
      } else if (this.props.error.message) {
        message = this.props.error.message;
      }
    }

    return (
      <div className="content-container">
        <div className="content-header">
          <h1>{translate('Error')}</h1>
        </div>

        <div className="content-main">
          <p style={{ marginTop: '4rem' }}>{message}</p>
        </div>
      </div>
    );
  }
}
