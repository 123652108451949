import * as React from 'react';

export default class DownChevronSmall extends React.Component {
  render() {
    let color = this.props.color;

    return React.createElement('svg', {
      className: this.props.className,
      width: '10px',
      height: '6px',
      viewBox: '0 0 10 6',
      dangerouslySetInnerHTML: {
        __html:
          '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g transform="translate(-294.000000, -25.000000)" stroke="' +
          color +
          '" stroke-width="2"><g transform="translate(0.000000, -1.000000)"><g transform="translate(56.000000, 1.000000)"><polyline transform="translate(243.000000, 28.000000) scale(-1, -1) rotate(-270.000000) translate(-243.000000, -28.000000) " points="245 32 241 28 245 24"></polyline></g></g></g></g>',
      },
    });
  }
}
