const UAParser = require('ua-parser-js');

class Platform {
  constructor() {
    const parser = new UAParser();
    const device = parser.getDevice();

    // OS
    let os = parser.getOS();
    this.OS = os.name;

    this.deviceType = device.type;

    // Browser
    this.browser = parser.getBrowser().name;
    this.browserVersion = parser.getBrowser().version;

    // Device Type

    // iOS (could be browser or app)
    this.isIos = this.isIosApp = /iOS APP/.test(parser.getUA());
    if (
      device.model === 'iPhone' ||
      device.model === 'iPad' ||
      device.model === 'iPod'
    ) {
      this.isIos = true;
    }

    // Check if this was launched by a custom ios webview (e.g. via Showbie)
    this.isIosView = /iOS View/.test(parser.getUA());
    this.isAndroidApp =
      /Android APP/.test(parser.getUA()) ||
      /((Android)*); wv|Version\/[0-9.]{1,} Chrome/.test(parser.getUA());
    if (this.isIos) {
      this.iosVersion = os.version;
    }
  }

  isMobile() {
    return this.isMobileOrTablet() || this.isAndroidApp || this.isIosApp;
  }

  isPhone() {
    return this.isMobile() && this.deviceType === 'mobile';
  }

  isSmallPhone() {
    return this.isPhone() && window.innerWidth < 375;
  }

  isIE() {
    return this.browser === 'IE';
  }

  isEdge() {
    return this.browser === 'Edge';
  }

  isFirefox() {
    return this.browser === 'Firefox';
  }

  isChrome() {
    return this.browser === 'Chrome';
  }

  isIOSView() {
    return this.isIosView;
  }

  isTabletPortrait() {
    return this.isTablet || window.screen.width === 768;
  }

  isWrapperApp() {
    return this.isIosApp || this.isAndroidApp;
  }

  isMobileOrTablet() {
    return this.deviceType === 'mobile' || this.deviceType === 'tablet';
  }
}
const platform = new Platform();

export default platform;
