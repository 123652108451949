import * as React from 'react';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

import CloseX from 'CloseX';

import Button from '../../oauth/components/buttons/Button';
import * as MessengerTypes from '../shared/messenger/MessengerTypes';
import { PostMessenger } from '../shared/messenger/PostMessenger';

export class EndActivityPromptView extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          padding: '4rem 0.5rem',
          textAlign: 'center',
        }}
      >
        <div
          className="cancel-button-container"
          style={{ position: 'fixed', top: '2rem', right: '2rem' }}
        >
          <div className="cancel-button">
            <CloseX
              className="cancel-button-x"
              color="#d7d8da"
              onClick={this.cancelClick}
            />
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 47 60"
          style={{ height: '180px' }}
        >
          <path
            fill="#d7d8da"
            fillRule="evenodd"
            d="M39.037 26.536h-8.646c-.976 0-1.879.513-2.367 1.346L23.7 35.267a2.658 2.658 0 0 0 0 2.693l4.323 7.383a2.741 2.741 0 0 0 2.367 1.346h8.646c.976 0 1.876-.513 2.365-1.346l4.324-7.383a2.658 2.658 0 0 0 0-2.693l-4.324-7.385a2.738 2.738 0 0 0-2.365-1.346m0-26.536h-8.646c-.976 0-1.879.513-2.367 1.346L23.7 8.73a2.656 2.656 0 0 0 0 2.693l4.323 7.384a2.741 2.741 0 0 0 2.367 1.346h8.646c.976 0 1.876-.513 2.365-1.346l4.324-7.384a2.656 2.656 0 0 0 0-2.693l-4.324-7.384A2.738 2.738 0 0 0 39.037 0M15.702 39.847H7.055c-.975 0-1.878.513-2.366 1.346L.366 48.577a2.654 2.654 0 0 0 0 2.691l4.323 7.386A2.743 2.743 0 0 0 7.055 60h8.647c.976 0 1.878-.514 2.366-1.346l4.323-7.386a2.654 2.654 0 0 0 0-2.69l-4.323-7.385a2.74 2.74 0 0 0-2.366-1.346m6.689-15.114l-4.323 7.384a2.74 2.74 0 0 1-2.366 1.347H7.055a2.742 2.742 0 0 1-2.366-1.347L.366 24.733a2.658 2.658 0 0 1 0-2.693l4.323-7.383a2.742 2.742 0 0 1 2.366-1.347h8.647a2.74 2.74 0 0 1 2.366 1.347l4.323 7.383c.488.833.488 1.86 0 2.693"
          />
        </svg>
        <h1
          style={{
            marginTop: '4rem',
            fontFamily: '"Montserrat", sans-serif',
            fontSize: '20px',
            fontWeight: '500',
          }}
        >
          {translate('Activity in Progress')}
        </h1>
        <p style={{ marginTop: '1rem' }}>
          {translate(
            'Would you like to stop the current activity and start a new one?'
          )}
        </p>
        <div
          className="content-footer"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '4rem',
          }}
        >
          <Button
            type="button"
            appearance="primary"
            styles={{ margin: '0 0.5rem' }}
            clickAction={this.props.continueClick}
          >
            {translate('Start New Activity')}
          </Button>
          <Button
            type="button"
            appearance="outline"
            styles={{ margin: '0 0.5rem' }}
            clickAction={this.cancelClick}
          >
            {translate('Cancel')}
          </Button>
        </div>
      </div>
    );
  }

  cancelClick() {
    PostMessenger.sendMessage(MessengerTypes.GENERAL.DISMISS, null);
  }
}
