import 'core-js/stable';
import 'regenerator-runtime/runtime';

/*----------------------------------------------------------------------------*/
/*    These statments ensure that webpack bundles the generic code we have    */
/*    written that is common between login.js, student.js, and teacher.js.    */
import { loadClientTranslations } from '@showbie-socrative/socrative-utils/lib/translator/client';
import { enableES5 } from 'immer';

import Dots from 'Dots';

// This is to enable IE11 support for immer JS
// https://immerjs.github.io/immer/installation/#immer-on-older-javascript-environments
enableES5();

require('../../shared/less/main-login.less');
require('extensions');

let request = require('../../../shared/Request');
let Messaging = require('Messaging');
let utils = require('Utils');
let platform = require('Platform');
let user = require('user');
let room = require('room');
let Input = require('Input');
let CloseX = require('CloseX');
let popup = require('../../shared/components/popup/PopupController');
let Select = require('Select');
let DownChevronSmall = require('DownChevronSmall');
let InfoIcon = require('InfoIcon');

/*----------------------------------------------------------------------------*/

let Backbone = require('backbone');

loadClientTranslations(null, function () {
  require.ensure(['TeacherLoginRouter'], function (require) {
    SOCRATIVE.checkTime.reset();
    SOCRATIVE.initTemplateLoader.stop(); // Hide the loading message (if it's visible)
    require('TeacherLoginRouter');
    Backbone.history.start();
  });
});
