let Backbone = require('backbone');
let localUser = require('user');
let question = require('../edit-quiz/LegacyQuestionModel');

let MediaResourceModel = Backbone.Model.extend({
  urlRoot: function () {
    if (localUser.isTeacher()) {
      return (
        window.backend_host +
        '/common/api/media-resources/{0}/'.format(question.get('question_id'))
      );
    } else {
      return window.backend_host + '/common/api/media-resources/';
    }
  },
});

module.exports = MediaResourceModel;
