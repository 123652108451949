module.exports = {
  GENERAL: {
    key: 'GENERAL',
    AUTHENTICATE: 'GENERAL.AUTHENTICATE',
    DISMISS: 'GENERAL.DISMISS',
  },
  ROOMS: {
    key: 'ROOMS',
    SELECT: 'ROOMS.SELECT_ROOM',
  },
  LAUNCH: {
    key: 'LAUNCH',
    READY: 'LAUNCH.READY',
    CONFIRM: 'LAUNCH.CONFIRM',
    COMPLETE: 'LAUNCH.COMPLETE',
  },
  QUIZ: {
    key: 'QUIZ',
    FINISHED: 'QUIZ.FINISHED',
  },
};
