import * as React from 'react';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

import Spinner from '../../integrations/components/spinner/Spinner';
import Button from './buttons/Button';

const request = require('../../../../shared/Request');

export class AuthorizeView extends React.Component {
  constructor() {
    super();
    this.state = { loading: true, saving: false };
    this.onInfoSuccess = this.onInfoSuccess.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  componentDidMount() {
    this.getInfo();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="content-container">
          <div className="content-main spinner-container">
            <Spinner />
          </div>
        </div>
      );
    } else {
      let url = `${window.teacher_service}/oauth/authorize/consent`;

      return (
        <form className="content-container" method="get" action={url}>
          <div className="content-header">
            <h1 style={{ maxWidth: '75%' }}>
              {translate(
                'Showbie is requesting access to your Socrative account.'
              )}
            </h1>
            <div className="content-header__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="58" height="50">
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#abe2fc"
                    d="M49.037 2.781l-.048-.156C48.611 1.344 47.647.547 46.305.531h-34.39c-1.152 0-2.036.563-2.51 1.5L.247 32.25.216 44.156c-.016 3.11 2.526 5.625 5.668 5.64l22.927.095 23.447-.016c3.142 0 5.684-2.516 5.684-5.625v-12L49.037 2.781zm-6.3 29.469a4.823 4.823 0 0 0-4.642 3.5 9.646 9.646 0 0 1-17.937 0c-.58-2.064-2.477-3.494-4.642-3.5H3.832l8.431-28.61h33.648l8.415 28.626-11.59-.016z"
                  />
                  <path
                    fill="#00a6e9"
                    fillRule="nonzero"
                    d="M35.458 10.078c-3.647-1.406-7.31-1.484-9.695-1.234-.616.047-1.137.14-1.595.219a9.143 9.143 0 0 0-1.152.234l-.948.234.016.016c-.537.172-.995.484-1.216.906-.49.875.127 1.86 1.358 2.188.6.171 1.216.14 1.769-.016v.016l.663-.172c.205-.063.474-.125.805-.188.316-.062.68-.14 1.105-.172 1.658-.203 4.232-.203 6.869.75 2.59.922 5.084 2.891 5.747 5.47.742 2.53-.631 5.483-3.616 7.28-1.5.875-3.3 1.516-5.194 1.75-1.88.25-3.821.141-5.558-.297-3.521-.828-5.921-2.859-6.821-4.812-.98-1.969-.584-3.766-.08-4.89.3-.563.553-1 .79-1.282l.332-.437-.016-.016.11-.11c.269-.468-.063-1-.726-1.187-.584-.156-1.2 0-1.531.36l-.032-.016-.442.531c-.316.344-.647.875-1.042 1.563-.316.718-.68 1.593-.758 2.656-.126 1.047.063 2.25.6 3.5 1.026 2.484 4.01 5.187 8.526 6.344 2.227.609 4.753.797 7.263.515 2.511-.265 4.943-1.047 6.98-2.187 4.089-2.328 6.11-6.282 5.242-9.797-.774-3.516-4.137-6.36-7.753-7.719z"
                  />
                </g>
              </svg>
            </div>
          </div>

          <div className="content-main">
            <input
              type="hidden"
              name="client_id"
              value={this.props.model.clientId}
            />
            <input type="hidden" name="state" value={this.props.model.state} />
            <input
              type="hidden"
              name="redirect_uri"
              value={this.props.model.redirectUri}
            />
            <input
              type="hidden"
              name="response_type"
              value={this.props.model.responseType}
            />
            <input
              type="hidden"
              name="transaction_id"
              value={this.state.transaction_id}
            />

            <p style={{ marginTop: '4rem' }}>
              {translate(
                `Showbie will be able to retrieve a list of your rooms and quizzes, modify your room rosters, launch quizzes, and retrieve quiz scores and reports. You may disable this connection later via your Showbie account settings.`
              )}
            </p>
          </div>

          <div className="content-footer">
            <Button
              disabled={this.state.saving}
              type="submit"
              appearance="primary"
              styles={{ width: '100%', marginTop: '4rem' }}
            >
              {translate('Allow Access')}
            </Button>
            <Button
              appearance="link"
              styles={{
                display: 'block',
                margin: '2rem auto 0',
                textAlign: 'center',
              }}
              clickAction={this.onCancelClick}
            >
              {translate('Cancel')}
            </Button>
          </div>
        </form>
      );
    }
  }

  getInfo() {
    let url = `${
      window.teacher_service
    }/oauth/authorize${this.props.model.getQueryParamString()}`;
    request.get({
      url: url,
      success: this.onInfoSuccess,
      error: (response) => {
        console.error(response);
        this.props.router.errorEvent = response;
        this.props.router.navigate('error', { trigger: true });
      },
    });
  }

  onInfoSuccess(info) {
    this.setState({
      first_name: info.first_name,
      last_name: info.last_name,
      transaction_id: info.transaction_id,
      loading: false,
    });
  }

  onCancelClick() {
    this.props.router.navigate('', { trigger: true });
  }
}
