import * as Sentry from '@sentry/browser';
import {
  ActivityStatus,
  QuestionTypes,
  STUDENT_NOT_FOUND_ERROR,
} from '@showbie-socrative/socrative-utils';
import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';
import {
  FinishActivityEventAdapter,
  LaunchExitTicketEventAdapter,
  LaunchMultipleChoiceEventAdapter,
  LaunchQuizEventAdapter,
  LaunchShortAnswerEventAdapter,
  LaunchSpaceRaceEventAdapter,
  LaunchTrueFalseEventAdapter,
  LaunchVoteEventAdapter,
} from '~analytics/index';
import { FinishRoute } from '~analytics/types';
import { isEmpty } from 'underscore';

import { currentRoomActivityChanged } from '~teacher/slices/teacher/teacherSlice';

import { finishActivity } from '../../clients/app/src/api/activities/activityApi';
import reduxStore from '../../clients/app/src/store';

const Backbone = require('backbone');
const _ = require('underscore');
const messaging = require('Messaging');
const Constants = require('Constants');
const request = require('../Request');
const room = require('room');
const user = require('user');
const store = require('store');
const Quiz = require('quiz-model');
const popup = require('../../clients/shared/components/popup/PopupController');

const showOutOfDateClientPopup = () => {
  popup.render({
    isModal: true,
    title: translate('Update Available'),
    message: translate(
      'A newer version of Socrative is available! Please refresh the page to get the latest updates.'
    ),
    buttonText: translate('Refresh'),
    buttonClicked: () => {
      window.location.reload();
    },
  });
};

const Activity = Backbone.Model.extend({
  initialize: function () {
    this.on('change:id', (model, value) => {
      let isLive = false;
      if (model.has('end_time')) {
        isLive = model.get('end_time') !== null;
      } else {
        isLive = model.has('start_time');
      }

      reduxStore.dispatch(
        currentRoomActivityChanged({
          activityInstanceId: value,
          isLive: isLive,
        })
      );
    });

    this.on('change:end_time', (model, value) => {
      let id = model.get('id');
      let isLive = value === null;
      reduxStore.dispatch(
        currentRoomActivityChanged({ isLive: isLive, activityInstanceId: id })
      );
    });
  },
  /* ------------------------- */
  /*   Activity Data Methods   */
  /* ------------------------- */

  getId: function () {
    return activity.get('id');
  },

  getFinalActivityType: function () {
    return activity.get('final_result_activity_type');
  },

  getQuizId: function () {
    return activity.get('quiz').id;
  },

  setFinalResultQuizId: function (id) {
    activity.set('finalResultQuizId', id);
  },

  getFinalResultQuizId: function () {
    return activity.get('finalResultQuizId');
  },

  hasFinalResultQuizId: function () {
    return activity.has('finalResultQuizId');
  },

  setFinalResultActivityId: function (id) {
    activity.set('finalResultActivityId', id);
  },

  getFinalResultActivityId: function () {
    return activity.get('finalResultActivityId');
  },

  getCurrentQuestionNumber: function () {
    // The 'activity_state' property stores the index of the current question, with one very important
    // exception: If the current question is the first question, 'activity_state' will be -1 (not 0).
    const questionIndex = activity.get('activity_state');
    return questionIndex === -1 ? 1 : questionIndex + 1;
  },

  getResponses: function (isLive) {
    let responseType = isLive ? 'responses' : 'final_result_responses';
    let responses = [];
    if (activity.has(responseType)) {
      for (let response of activity.get(responseType)) {
        if (!response.hidden) {
          responses.push(response);
        }
      }
    }
    return responses;
  },

  setResponseNames: function (isLive, responsesByUser) {
    const names = activity.get(
      isLive ? 'student_names' : 'final_result_student_names'
    );
    _.each(responsesByUser, (response) => {
      response.student_name = names[response.user_uuid];
    });
  },

  /* ----------------------------------- */
  /*   Activity Type Codes and Methods   */
  /* ----------------------------------- */

  QUIZ: 'QZ',
  SPACE_RACE: 'SR',
  NO_REPORT: 'NR', // The type code for QQ-TF and QQ-MC activities, because we don't provide reports for them.
  QQ_SA: '1Q',
  VOTE: '1V',

  isQuickQuestion: function () {
    const type = activity.get('activity_type');
    return type === activity.QQ_SA || type === activity.NO_REPORT;
  },

  isQuickQuestionShortAnswer: function () {
    const quiz = activity.get('quiz');
    const typeIsFR = quiz && quiz.questions[0].get('type') === 'FR';
    return activity.isQuickQuestion() && typeIsFR;
  },

  isVote: function () {
    return activity.get('activity_type') === activity.VOTE;
  },

  isQuiz: function () {
    return activity.get('activity_type') === activity.QUIZ;
  },

  isSpaceRace: function () {
    return activity.get('activity_type') === activity.SPACE_RACE;
  },

  isNonSpaceRace: function () {
    return (
      activity.has('activity_type') &&
      (activity.isQuiz() || activity.isQuickQuestion() || activity.isVote())
    );
  },

  getDisplayType: function (type, name) {
    if (type === activity.SPACE_RACE) {
      return translate('Space Race');
    } else if (type === activity.QUIZ) {
      return name === 'Exit Ticket Quiz'
        ? translate('Exit Ticket')
        : translate('Quiz');
    } else if (type === activity.QQ_SA) {
      return translate('Short Answer');
    }
  },

  /* ----------------------------- */
  /*   Activity Settings Methods   */
  /* ----------------------------- */

  isTeacherPaced: function () {
    return (
      activity.has('settings') && activity.get('settings').pacing === 'teacher'
    );
  },

  needsStudentName: function () {
    return activity.has('settings') && activity.get('settings').require_names;
  },

  /* ----------------------------- */
  /*   Activity Lifetime Methods   */
  /* ----------------------------- */

  prepareForNewActivity: function () {
    activity.stopCountdown();
    activity.clear({ silent: true });
  },

  isLive: function () {
    let id = activity.get('id');

    if (!id) {
      return false;
    }

    try {
      const state = reduxStore.getState();
      const currentRoomActivity = state.teachers.teacher.currentRoomActivity;
      if (currentRoomActivity.activityInstanceId === id) {
        return currentRoomActivity.isLive;
      }
    } catch (err) {
      console.error('Error parsing currentRoomActivity state', err);
      return false;
    }

    return false;
  },

  isQuizRunning: function () {
    return activity.has('id') && activity.has('quiz');
  },

  endCurrentActivity: function (callback, keepData, finishRoute) {
    finishActivity(activity.getId())
      .then((results) => {
        if (!keepData) {
          activity.clear();
        }

        // Analytics
        new FinishActivityEventAdapter({
          isRosteredRoom: results.data['isRostered'],
          settings: results.data['settings'],
          activityType: results.data['activityType'],
          numberStudents: results.data['students'].length,
          numberResponses: results.data['responses'].length,
          startTime: results.data['startTime'],
          endTime: results.data['endTime'],
          finishRoute: finishRoute,
        }).sendAnalytics();

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },

  /* ------------------------------ */
  /*   Activity Countdown Methods   */
  /* ------------------------------ */

  setCountdown: function (seconds) {
    activity.set({ countdown: seconds }, { silent: true });
  },

  getCountdown: function () {
    return activity.get('countdown');
  },

  hasCountdown: function () {
    return activity.has('countdown');
  },

  setCountdownStarted: function (started) {
    activity.set({ countdownStarted: started }, { silent: true });
  },

  isCountdownStarted: function () {
    return activity.has('countdownStarted') && activity.get('countdownStarted');
  },

  shouldStartCountdown: function () {
    return (
      activity.hasCountdown() &&
      activity.getCountdown() !== -1 &&
      !activity.isCountdownStarted()
    );
  },

  startCountdown: function () {
    activity.setFinishedOnRefresh(false);
    activity.setCountdownStarted(true);
    activity.setCountdownId(window.setInterval(activity.updateCountdown, 1000));
  },

  addCountdownCallback: function (callback) {
    let countdownCallbacks = activity.getCountdownCallbacks();
    if (countdownCallbacks) {
      for (let i = 0; i < countdownCallbacks.length; i++) {
        if (callback === countdownCallbacks[i]) {
          return;
        }
      }
    } else {
      countdownCallbacks = [];
      activity.setCountdownCallbacks(countdownCallbacks);
    }
    countdownCallbacks.push(callback);
  },

  setCountdownCallbacks: function (value) {
    this.set('countdownCallbacks', value);
  },

  getCountdownCallbacks: function () {
    return this.get('countdownCallbacks');
  },

  setCountdownId: function (id) {
    activity.set({ countdownId: id }, { silent: true });
  },

  getCountdownId: function () {
    return activity.get('countdownId');
  },

  updateCountdown: function () {
    activity.setCountdown(activity.getCountdown() - 1);
    if (activity.getCountdown() === 0) {
      activity.stopCountdown();
      activity.endCurrentActivity(null, true, FinishRoute.Countdown);
    }
    const countdownCallbacks = activity.getCountdownCallbacks();
    if (countdownCallbacks) {
      for (let i = 0; i < countdownCallbacks.length; i++) {
        const callback = countdownCallbacks[i];
        callback();
      }
    }
  },

  stopCountdown: function () {
    window.clearInterval(activity.getCountdownId());
  },

  shouldShowCountdown: function () {
    return activity.hasCountdown() && activity.getCountdown() !== -1;
  },

  getCountdownMinutes: function () {
    return Math.floor(activity.getCountdown() / 60);
  },

  getCountdownSeconds: function () {
    return Math.floor(activity.getCountdown() % 60);
  },

  getCountdownDisplay: function () {
    const minutes = activity.getCountdownMinutes();
    let seconds = activity.getCountdownSeconds();
    if (seconds > -1 && seconds < 10) {
      seconds = '0' + seconds;
    }
    return minutes + ':' + seconds;
  },

  /**
   * Stop the countdown and reset its properties.
   * @param {number} seconds The optional number of seconds for the countdown. Defaults to -1 (disabled).
   */
  resetCountdown: function (seconds) {
    activity.stopCountdown();
    activity.setCountdown(seconds || -1);
    activity.setCountdownStarted(false);
  },

  isCountdownDone: function () {
    return activity.hasCountdown() && activity.getCountdown() === 0;
  },

  hasCountdownAfterRefresh: function () {
    const settings = activity.get('settings');
    return settings && settings.seconds && settings.seconds > -1;
  },

  getCountdownAfterRefresh: function () {
    return (
      activity.get('settings').seconds -
      Math.floor(Date.now() / 1000 - activity.get('start_time'))
    );
  },

  setFinishedOnRefresh: function (value) {
    this.set('finishedOnRefresh', value);
  },

  getFinishedOnRefresh: function () {
    return this.get('finishedOnRefresh');
  },

  isActivityPaused: function () {
    return activity.get('activity_paused');
  },

  setIsActivityPaused: function (value) {
    activity.set('activity_paused', value);
  },

  handleCountdownAfterRefresh: function () {
    if (activity.hasCountdownAfterRefresh()) {
      const timeLeft = activity.getCountdownAfterRefresh();
      if (timeLeft > 0) {
        if (store.get('countdownStopped') !== true) {
          activity.resetCountdown(timeLeft);
          activity.startCountdown();
        }
      } else {
        activity.setCountdown(0);
        activity.setCountdownStarted(true);
        activity.setFinishedOnRefresh(true);
        activity.endCurrentActivity(null, true, FinishRoute.Countdown);
      }
    }
  },
});

let activity = new Activity();

activity.checkBeforeStartingActivity = function (callback, options) {
  if (activity.isLive()) {
    popup.render({
      title: translate('Please Confirm'),
      message: translate(
        'Would you like to stop the current activity and start a new one?'
      ),
      buttonText: translate('Yes'),
      cancelText: translate('No'),
      buttonClicked: async () => {
        const finishedActivity = await finishActivity(activity.getId());
        // Analytics
        if (finishedActivity.data) {
          new FinishActivityEventAdapter({
            isRosteredRoom: finishedActivity.data['isRostered'],
            settings: finishedActivity.data['settings'],
            activityType: finishedActivity.data['activityType'],
            numberStudents: finishedActivity.data['students'].length,
            numberResponses: finishedActivity.data['responses'].length,
            startTime: finishedActivity.data['startTime'],
            endTime: finishedActivity.data['endTime'],
            finishRoute: FinishRoute.StartNewActivity,
          }).sendAnalytics();
        }

        if (callback) {
          callback(options);
        }
      },
      cancelClicked: () => {
        if (options && options.error) {
          options.error();
        }
      },
    });
    return;
  }
  if (callback) {
    callback(options);
  }
};

activity.validateCachedQuiz = function () {
  if (!activity.has('quiz')) {
    return false;
  }

  const quiz = activity.get('quiz');

  if (
    !(_.isObject(quiz) && _.isFunction(quiz.has) && _.isFunction(quiz.get)) ||
    quiz.get('id') !== activity.get('activity_id') ||
    !_.isArray(quiz.questions)
  ) {
    activity.unset('quiz');
    return false;
  }

  return true;
};

activity.getCurrentQuiz = function (callback) {
  if (!this.has('activity_id')) {
    callback(null);
    return;
  }
  if (activity.validateCachedQuiz()) {
    callback(this.get('quiz'));
  } else {
    const currentQuiz = new Quiz({ id: this.get('activity_id') });
    currentQuiz.fetch({
      success: function () {
        currentQuiz.initialize();
        activity.set('quiz', currentQuiz);
        callback(currentQuiz);
      },
      error: function (error, response) {
        if (
          response &&
          response.error &&
          response.error.code === Constants.QUIZ_UNSUPPORTED_VERSION
        ) {
          Sentry.captureException(response.error.message);
          showOutOfDateClientPopup();
        }
        return;
      },
    });
  }
};

activity.loadFinalResult = function (activityId, quizId, callback) {
  if (
    activity.getFinalResultActivityId() === activityId &&
    activity.getFinalResultQuizId() === quizId
  ) {
    callback(true); // eslint-disable-line
    return; // If we already have the final result data, don't load it again (SOC-957).
  }
  request.get({
    url: `${window.backend_host}/activities/api/report/${activityId}`,
    success: (data) => {
      let studentNames = {};
      if (data.student_names.length > 0 && data.student_names[0].student_id) {
        activity.set('final_result_rostered', true);
        _.each(data.student_names, (student) => {
          studentNames[student.student_id] = student.name;
        });
      } else {
        activity.set('final_result_rostered', false);
        _.each(data.student_names, (student) => {
          studentNames[student.user_uuid] = student.name;
        });
      }

      activity.set('final_result_students', data.student_names);
      activity.set('final_result_student_names', studentNames);
      activity.set('final_result_responses', data.responses);
      activity.set('final_result_activity_type', data.activity_type);
      activity.set(
        'final_result_date',
        new Date(data.start_time * 1000).toDateString()
      );
      activity.setFinalResultActivityId(activityId);
      activity.setFinalResultQuizId(quizId);

      let finalResult = new Quiz({ id: quizId });
      finalResult.fetch({
        success: () => {
          finalResult.initialize();
          activity.set('final_result', finalResult);
          callback(true); // eslint-disable-line
        },
        error: (model, response, options) => {
          console.error('Error fetching quiz for final results:');
          console.error(model);
          console.error(response);
          console.error(options);
          Sentry.captureException(response.error.message);
          if (
            response.error &&
            response.error.code === Constants.QUIZ_UNSUPPORTED_VERSION
          ) {
            showOutOfDateClientPopup();
            return;
          }
          callback(false);
        },
      });
    },
    error: (response) => {
      console.error(response);
      callback();
    },
  });
};

activity.setStudentName = function (name, complete, error) {
  if (user.isTeacher()) {
    // TODO: Get rid of this garbage
    throw new Error('Exception: only students should be setting their name');
  }
  request.post({
    url: `${window.backend_host}/students/api/set-name/`,
    data: {
      activity_instance_id: activity.get('id'),
      student_name: name,
    },
    success: () => {
      if (_.isFunction(complete)) {
        complete();
      }
    },
    error: (response) => {
      if (error && _.isFunction(error)) {
        error(response);
      }
    },
  });
};

activity.setSettings = function (data, options) {
  if (_.isArray(data.settings)) {
    const settings = data.settings;
    const newSettings = {};
    for (let i = settings.length - 1; i >= 0; i--) {
      const key = settings[i].key;
      if (
        _.contains(
          ['pacing', 'team_assignment_type', 'team_count', 'icon_type'],
          key
        )
      ) {
        newSettings[key] = settings[i].value;
      } else if (key === 'seconds') {
        const seconds = parseInt(settings[i].value);
        newSettings[key] = isNaN(seconds) ? -1 : seconds;
      } else {
        let value = settings[i].value;
        if (_.isString(value)) {
          newSettings[key] = settings[i].value.toLowerCase() === 'true';
        } else {
          newSettings[key] = settings[i].value === true;
        }
      }
    }

    data.settings = newSettings;
  }

  activity.set(data, options);
};

activity.reset = function () {
  activity.__hasRefreshed = false;
  activity.__refreshing = false;
  activity.__refreshingCallbacks = [];
  activity.clear();
};

activity.__hasRefreshed = false;
activity.__refreshing = false;
activity.__refreshingCallbacks = [];

activity.refresh = function (success) {
  // If we've already refreshed this session, just return the refreshments.
  // Any changes that happen to the activity should come in from pubnub,
  // so we shouldn't have to hit the DB again to have up-to-date data.
  if (this.__hasRefreshed) {
    _.defer(() => {
      success();
    });
    return;
  }

  this.__refreshingCallbacks.push(success);

  if (!this.__refreshing) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.__refreshing = true;

    this.__refresh(
      // success
      function (result) {
        that.__refreshing = false;
        that.__hasRefreshed = true;

        _.each(that.__refreshingCallbacks, function (callback) {
          callback();
        });

        that.__refreshingCallbacks.length = 0;
      }
    );
  }
};

activity.__refresh = function (success) {
  const runRefresh = function () {
    const runSuccessCallback = function () {
      if (!_.isUndefined(success)) {
        success();
      }
    };

    if (!activity.has('started_time')) {
      const roomName = room.get('name');
      if (_.isUndefined(roomName)) {
        // TODO: Get rid of this garbage
        throw new Error(
          'InvalidOperationException: room_name needs to be set before loading the local activity.'
        );
      }

      let url;
      if (window.is_integration) {
        url = `${window.teacher_service}/activity/${activity.get('id')}`;
      } else {
        url = `${window.backend_host}/rooms/api/current-activity/${roomName}`;
      }

      request.get({
        url: url,
        success: (data) => {
          if (!data || isEmpty(data)) {
            runSuccessCallback(); // There is no activity.
            return;
          }

          const studentNames = {};
          let originalStudentNames = data.student_names;
          let isRostered = false;
          let isFinished = !!data.end_time;

          for (let i in data.student_names) {
            if (data.student_names[i].student_id) {
              isRostered = true;
            }
            const studentNameInfo = data.student_names[i];
            studentNames[studentNameInfo.user_uuid] = studentNameInfo.name;
          }
          data.student_names = studentNames;

          activity.setSettings(data);
          activity.set('activity_paused', data.state === ActivityStatus.Paused);

          if (user.isTeacher()) {
            activity.handleCountdownAfterRefresh();

            if (window.is_integration && isFinished) {
              activity.set('final_result_rostered', isRostered);
              activity.set('final_result_students', originalStudentNames);
              activity.set('final_result_student_names', studentNames);
              activity.set('final_result_responses', data.responses);
              activity.set('final_result_activity_type', data.activity_type);
              activity.set(
                'final_result_date',
                new Date(data.start_time * 1000).toDateString()
              );
              activity.setFinalResultActivityId(data.activity_id);
              activity.setFinalResultQuizId(data.quiz.id);
            }
          }

          if (activity.has('quiz')) {
            if (_.isUndefined(activity.get('quiz').cid)) {
              let quizField = isFinished ? 'final_result' : 'quiz';
              let newQuiz = new Quiz(activity.get('quiz'));
              if (isFinished) {
                newQuiz.initialize();
              }
              activity.set(quizField, newQuiz);
            }

            runSuccessCallback();
          } else {
            runSuccessCallback();
          }
        },
        error: (response) => {
          console.error(response);
        },
      });
    } else {
      runSuccessCallback();
    }
  };

  runRefresh();
};

/**
 * Currently only used for students
 *
 * Load the quiz and responses associated with the current activity. When loading is successful, the quiz
 * and responses will be available at {@code activity.get('quiz')} and {@code activity.get('responses')}.
 * @param {function} successCallback The function to execute when the quiz and responses have loaded.
 * @param {function} errorCallback The function to execute if a loading error occurs.
 */
activity.loadQuizAndResponses = function (successCallback, errorCallback) {
  request.get({
    url: `${window.teacher_service}/quizzes/${activity.get(
      'activity_id'
    )}/student?room=${room.get('name')}`,
    success: (data) => {
      if (data && data.version > window.supported_flags.quiz_editor_version) {
        showOutOfDateClientPopup();
        return;
      }

      activity.set({ quiz: data }, { silent: true });
      request.get({
        url: `${window.backend_host}/students/api/responses/${activity.get(
          'id'
        )}/`,
        success: (data) => {
          activity.set({ responses: data }, { silent: true });
          if (successCallback) {
            successCallback();
          }
        },
        error: (response) => {
          console.error('Error getting student responses:', response);
          if (errorCallback) {
            errorCallback();
          }
        },
      });
    },
    error: (response) => {
      if (response.code && response.code === STUDENT_NOT_FOUND_ERROR.code) {
        user.logout(() => {
          window.location.href = '/login/student/';
        });
      } else if (
        response.error &&
        response.error.code === Constants.QUIZ_UNSUPPORTED_VERSION
      ) {
        Sentry.captureException(response.error.message);
        showOutOfDateClientPopup();
      }
      console.error('Error getting student quiz:', response);
    },
  });
};

activity.handleStudentResponse = function (data) {
  data = JSON.parse(data);

  if (data.activity_instance !== activity.get('id')) {
    return;
  }

  activity.updateStudentName(
    {
      user_uuid: data.user_uuid,
      student_name: data.student_name,
    },
    true
  );

  let responses = _.clone(activity.get('responses')) || []; // Clone the responses to force a Backbone change event.
  let existingResponse = _.findWhere(responses, { id: data.id });

  if (!existingResponse || activity.get('settings').allow_repeat_responses) {
    responses.push(data);
  } else {
    responses[responses.indexOf(existingResponse)] = data;
  }

  activity.set('responses', responses);
};

activity.updateStudentName = function (studentNameData, silent) {
  const studentNames = {};

  if (activity.has('student_names')) {
    _.extend(studentNames, activity.get('student_names'));
  }

  studentNames[studentNameData.user_uuid] = studentNameData.student_name;
  activity.set('student_names', studentNames, { silent: silent });
};

if (user.isStudent()) {
  messaging.addListener({
    message: Constants.ACTIVITY_CHANGE,
    callback: (data) => {
      user.unset('team');
      if (data === 'activity_ended') {
        user.unset('user_input_name');
        activity.clear();
      } else if (data === 'activity_paused') {
        activity.setIsActivityPaused(true);
      } else if (data === 'activity_resumed') {
        activity.setIsActivityPaused(false);
      } else {
        activity.setSettings(data);
      }
    },
  });
}

if (user.isTeacher()) {
  messaging.addListener({
    message: Constants.STUDENT_RESPONSE,
    callback: activity.handleStudentResponse,
  });
  messaging.addListener({
    message: Constants.STUDENT_NAME_SET,
    callback: activity.updateStudentName,
  });
}

activity.hideResponse = function (responseId, callback) {
  if (activity.has('responses')) {
    let existingResponse = _.findWhere(activity.get('responses'), {
      id: responseId,
    });

    if (existingResponse) {
      let data = new window.FormData();
      data.append('response_id', responseId);
      data.append('room_name', room.get('name'));

      request.post({
        url: `${window.backend_host}/students/api/hide-response/`,
        data: data,
        success: () => {
          existingResponse.hidden = true;
          callback();
        },
        error: (response) => {
          console.error(response);
        },
      });
    }
  }
};

activity.startQuiz = function (roomId, quizId, options, launchRoute) {
  request.post({
    url: `${window.teacher_service}/activity/quiz`,
    data: {
      room_id: roomId,
      quiz_id: quizId,
      settings: options.settings,
    },
    success: (data) => {
      new LaunchQuizEventAdapter({
        settings: options.settings,
        isRosteredRoom: room.hasRoster(),
        launchRoute: launchRoute,
      }).sendAnalytics();

      activity.prepareForNewActivity();
      activity.setSettings(data);
      activity.set(
        { hideStudentResponses: false, hideStudentName: false },
        { silent: true }
      );
      options.success(data);
    },
    error: (response) => {
      console.error(response);
      if (options.error && _.isFunction(options.error)) {
        options.error(response);
      } else {
        throw response;
      }
    },
  });
};

activity.startQuickQuestion = function (options) {
  if (options.type.toLowerCase() === 'fr') {
    activity.startQuickQuestionConfirmed(options);
  } else {
    this.checkBeforeStartingActivity(
      activity.startQuickQuestionConfirmed,
      options
    );
  }
};

activity.startQuickQuestionConfirmed = function (options) {
  request.post({
    url: `${window.backend_host}/lecturers/api/start-quick-question/`,
    data: {
      room_name: room.get('name'),
      question_type: options.type,
      question_text: options.text,
      allow_repeat_responses: options.allow_repeat_responses,
      require_names: options.require_names,
      one_attempt: options.one_attempt,
    },
    success: (data) => {
      const isRosteredRoom = room.hasRoster && room.hasRoster();
      switch (options.type.toUpperCase()) {
        case QuestionTypes.FreeResponse:
          new LaunchShortAnswerEventAdapter(
            options,
            isRosteredRoom
          ).sendAnalytics();
          break;
        case QuestionTypes.MultipleChoice:
          new LaunchMultipleChoiceEventAdapter(isRosteredRoom).sendAnalytics();
          break;
        case QuestionTypes.TrueFalse:
          new LaunchTrueFalseEventAdapter(isRosteredRoom).sendAnalytics();
          break;
        default:
          console.error('Invalid quick question type');
      }

      activity.prepareForNewActivity();
      activity.setSettings(data);
      activity.set({ pacing: 'teacher' }, { silent: true });
      if (options.callback) {
        options.callback();
      }
    },
    error: (response) => {
      console.error(response);
    },
  });
};

activity.convertCurrentQuickQuestionToSurvey = function (callback) {
  request.post({
    url: `${window.backend_host}/lecturers/api/convert-quick-question-to-vote/`,
    data: {
      room_name: room.get('name'),
      require_names: false,
    },
    success: (data) => {
      new LaunchVoteEventAdapter(room.hasRoster()).sendAnalytics();
      activity.setSettings(data);
      activity.set({ pacing: 'teacher' }, { silent: true });
      if (_.isFunction(callback)) {
        callback();
      }
    },
    error: (response) => {
      console.error(response);
    },
  });
};

activity.startSpaceRace = function (options) {
  request.post({
    url: `${window.backend_host}/lecturers/api/start-space-race/`,
    data: options.settings,
    success: (data) => {
      new LaunchSpaceRaceEventAdapter(
        options.settings,
        room.hasRoster()
      ).sendAnalytics();

      // Unsubscribe and resubscribe to live events to ensure everything set up
      const roomName = options.settings.room_name;
      messaging.unsubscribe({ channel: `${roomName}-teacher` });
      messaging.subscribeToMessages({
        channel: `${roomName}-teacher`,
      });

      activity.prepareForNewActivity();
      activity.setSettings(data);
      options.success();
    },
    error: (response) => {
      if (_.isFunction(options.error)) {
        options.error();
      }
      console.error(response);
    },
  });
};

activity.showExitTicketErrorPopup = function () {
  popup.render({
    title: translate('Unknown Error'),
    message: translate(
      'An exit ticket could not be started. Please try again later.'
    ),
  });
};

activity.startExitTicket = function (options) {
  this.checkBeforeStartingActivity(function (options) {
    request.post({
      url: `${window.backend_host}/lecturers/api/start-exit-ticket/`,
      data: {
        room_name: room.get('name'),
      },
      success: (data) => {
        new LaunchExitTicketEventAdapter(room.hasRoster()).sendAnalytics();

        activity.prepareForNewActivity();
        activity.setSettings(data);
        activity.set(
          { hideStudentResponses: false, hideStudentName: false },
          { silent: true }
        );
        if (_.isObject(options) && _.isFunction(options.success)) {
          options.success();
        }
      },
      error: (response) => {
        console.error(response);
        activity.showExitTicketErrorPopUp();
      },
    });
  }, options);
};

module.exports = activity;
