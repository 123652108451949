import * as React from 'react';

import AlertIcon from 'AlertIcon';

export default class Tooltip extends React.Component {
  render() {
    let className = this.props.className ? ' ' + this.props.className : '';

    if (this.props.title) {
      className += ' tooltip-title';
    }

    let location = this.props.location || 'below';
    let title = null;

    if (this.props.title) {
      title = (
        <p>
          <AlertIcon className="input-error-icon" />
          <span className="tooltip-title-text">{this.props.title}</span>
        </p>
      );
    }

    return (
      <div className={'tooltip-container-' + location + className}>
        <div className="tooltip">
          <div className={'triangle-left-' + location} />
          <div className={'triangle-right-' + location} />
          {title}
          <span className="tooltip-text">{this.props.text}</span>
        </div>
      </div>
    );
  }
}
