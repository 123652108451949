/**
 * Socrative Request - A module for sending requests via XMLHttpRequest
 *
 * Supported Properties
 *
 *     complete  {function} Optional function to execute after the success or error callback is executed.
 *     data      {object}   Optional object containing the data to send (optional because it's not used for GET requests).
 *     error     {function} Required function that will be executed if there is an error sending the request, or if the response is an error.
 *     progress  {function} Optional function to execute when progress events are fired during file upload.
 *     stringify {boolean}  Optional flag indicating whether the data should be passed to JSON.stringify() before being sent (defaults to true).
 *     success   {function} Required function that will be executed if the response is not an error.
 *     url       {string}   Required URL for the request.
 */

'use strict';

const Constants = require('./Constants');

class Request {
  get(props) {
    this.send('GET', props);
  }

  post(props) {
    this.send('POST', props);
  }

  put(props) {
    this.send('PUT', props);
  }

  del(props) {
    this.send('DELETE', props);
  }

  send(type, props) {
    const request = new window.XMLHttpRequest();
    let response = {};
    const standardsRequest =
      props.url.indexOf('app.masteryconnect.com/api/query_types') !== -1;

    request.withCredentials =
      props.url !== Constants.UPLOADS_HOSTNAME && !standardsRequest; // Allows passing cookies from/to subdomains (.socrative.com).

    if (props.progress) {
      request.upload.addEventListener('progress', (event) => {
        props.progress(event);
      });
    }

    request.addEventListener('load', () => {
      let callback = props.error;
      let authErrorHandler = (response) => {
        if (
          window.location.pathname === '/login/teacher/' ||
          window.location.pathname === '/login/student/'
        ) {
          // The teacher is already at the login page, so redirecting there would create an infinite loop.
          // Instead, just invoke the original error callback, which should render the login view.
          callback(response);
        } else if (
          window.location.pathname.startsWith('/oauth') ||
          window.location.pathname.startsWith('/integration')
        ) {
          // Hack to bypass redirect for integration routes
          callback(response);
        } else if (window.location.pathname.startsWith('/student')) {
          // Students should redirect to student login
          window.location.href = '/login/student/';
        } else {
          window.location.href = '/login/teacher/';
        }
      };

      if (
        request.status >= Constants.HTTP_200_OK &&
        request.status < Constants.HTTP_400_BAD_REQUEST
      ) {
        callback = props.success;
      }

      if (request.status === Constants.HTTP_401_UNAUTHORIZED) {
        try {
          if (request.response === '') {
            response = {};
          } else {
            response = JSON.parse(request.response);
          }

          authErrorHandler(response);
        } catch (error) {
          authErrorHandler({});
        }
        return;
      }

      try {
        if (request.status !== Constants.HTTP_204_NO_CONTENT) {
          response = JSON.parse(request.response);
        }
      } catch (error) {
        console.error('Error parsing JSON from XMLHttpRequest:', error);
        callback = props.error;
      }

      if (
        response.error &&
        (response.error === Constants.AUTH_TOKEN_MISSING ||
          response.error.code === Constants.AUTH_TOKEN_MISSING)
      ) {
        authErrorHandler(response);
        return;
      }

      response["httpCode"] = request.status;
      callback(response);

      if (props.complete) {
        props.complete();
      }
    });

    request.addEventListener('error', (errorEvent) => {
      console.error('Unknown error in XMLHttpRequest:', errorEvent);
      props.error(response);
      if (props.complete) {
        props.complete();
      }
    });

    request.open(type, props.url);

    if (request.withCredentials && window.is_integration) {
      request.setRequestHeader(
        'X-Auth-Mode',
        Constants.HEADER_AUTH_INTEGRATION
      );
      request.setRequestHeader(
        'X-Integration-Auth',
        window.sessionStorage.getItem(Constants.INTEGRATION_AUTH_SESSION_NAME)
      );
    }

    if (type.toUpperCase() === 'POST' || type.toUpperCase() === 'PUT') {
      const isFormData = props.data instanceof window.FormData;

      if (!isFormData && !standardsRequest) {
        request.setRequestHeader(
          'Content-Type',
          'application/json; charset=UTF-8'
        );
      } else if (standardsRequest) {
        request.setRequestHeader(
          'Content-Type',
          'application/x-www-form-urlencoded; charset=UTF-8'
        );
      }

      props.data =
        isFormData || props.stringify === false || standardsRequest
          ? props.data
          : JSON.stringify(props.data);

      request.send(props.data);
    } else {
      request.send();
    }
  }

  jsonp(props) {
    const jsonpScript = document.createElement('script');
    jsonpScript.async = !0;

    const onJsonpError = () => {
      jsonpScript.removeEventListener('error', onJsonpError);
      props.error();
    };

    jsonpScript.addEventListener('error', onJsonpError);
    jsonpScript.src = `${props.url}?callback=socrativeJsonpResponse`;

    // JSONP requires the callback function to exist in the global scope
    // before its associated script tag is inserted into the DOM:
    window.socrativeJsonpResponse = function (data) {
      props.success(data);
      if (jsonpScript && jsonpScript.parentNode) {
        jsonpScript.parentNode.removeChild(jsonpScript);
      }
    };

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(jsonpScript, firstScript);
  }
}

module.exports = new Request();
