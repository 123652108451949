import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { ErrorView } from '../components/ErrorView';
import { GatekeeperView } from '../components/GatekeeperView';

export class GatekeeperController {
  constructor(router) {
    this.router = router;
  }

  view(token, options) {
    ReactDOM.render(
      <GatekeeperView controller={this} token={token} options={options} />,
      document.getElementById('main-content')
    );
  }

  handleRedirect(options) {
    try {
      this.router.navigate(options.destination, { trigger: true });
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error) {
    ReactDOM.render(
      <ErrorView error={error} />,
      document.getElementById('main-content')
    );
  }
}
