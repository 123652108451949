import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { AuthorizeView } from '../components/AuthorizeView';
import { ErrorView } from '../components/ErrorView';
import { ForgotPasswordView } from '../components/ForgotPasswordView';
import { LoginView } from '../components/LoginView';

let model = require('../models/OAuthModel');

export class OAuthController {
  constructor(router) {
    this.router = router;
  }

  viewOAuthLogin(clientId, state, redirectUri, responseType) {
    if (clientId && state && redirectUri && responseType) {
      model.setParameters(clientId, state, redirectUri, responseType);
    }
    ReactDOM.render(
      <LoginView model={model} router={this.router} />,
      document.getElementById('main-content')
    );
  }

  viewOAuthForgotPassword() {
    ReactDOM.render(
      <ForgotPasswordView router={this.router} />,
      document.getElementById('main-content')
    );
  }

  viewOAuthAuthorize() {
    ReactDOM.render(
      <AuthorizeView model={model} router={this.router} />,
      document.getElementById('main-content')
    );
  }

  viewErrorRoute() {
    ReactDOM.render(
      <ErrorView router={this.router} />,
      document.getElementById('main-content')
    );
  }
}
