import * as Sentry from '@sentry/browser';
import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';
import Backbone from 'backbone';
import * as _ from 'underscore';

import popup from '../../clients/shared/components/popup/PopupController';
import Constants from '../Constants';

const setLocalStorage = function () {
  try {
    return window.localStorage;
  } catch (error) {
    console.error(
      error,
      'Unable to access local storage. This might be caused by cookies being disabled or other reasons affecting local storage access.'
    );
    Sentry.captureException(error);
    return {};
  }
};
const localStorage = setLocalStorage();
const offlineStorage = {};
const ignoreErrorCodes = [
  Constants.STUDENT_NAME_MISSING,
  Constants.STUDENT_NOT_FOUND,
  Constants.ACTIVITY_HAS_ENDED,
  Constants.STUDENT_AUTH_FAILURE,
  Constants.ACTIVITY_PAUSED,
];

offlineStorage.saveToLocalStorage = function (model) {
  if (model.has('id') && _.isString(model.name)) {
    localStorage.setItem(
      model.name + '-' + String(model.get('id')),
      JSON.stringify(model.toJSON())
    );
  }
};

/**
 * function used to wrap around the Backbone save function
 * it uses localStorage as a fallback save option
 *
 * @param   {object} model      the backbone instance
 * @param   {object} attributes the attributes sent to the save function
 * @param   {object} options    the options sent to the BB save function
 *
 * @returns the normal save object (I think it's a jqXHR)
 */
offlineStorage.save = function (model, attributes, options) {
  // if this is undefined then the error callbacks will not be called
  if (!attributes || typeof attributes === 'undefined') {
    attributes = null;
  }

  if (!options || typeof options === 'undefined') {
    options = {};
  }

  let successFunction =
    typeof options.success === 'function' ? options.success : function () {};
  let errorFunction =
    typeof options.error === 'function' ? options.error : function () {};

  options.success = function (data, request) {
    successFunction(data, request);
  };

  let attempts = 0;
  options.error = function (data, request) {
    if (request.status === 500) {
      popup.render({
        title: translate('Unknown Error'),
        message: translate(
          'Your latest changes were not saved. Please check your connection and try again.'
        ),
      });
      return;
    }

    if (request && request.error) {
      if (request.error.message) {
        console.error(request.error.message);
      } else {
        console.error(request.error);
      }
    } else {
      console.error(request);
    }

    // wait 2.5 seconds and try again
    setTimeout(function () {
      if (attempts < 2) {
        attempts++;
        Backbone.Model.prototype.save.call(model, attributes, options);
      } else {
        if (
          request &&
          request.error &&
          request.error.message &&
          !_.contains(ignoreErrorCodes, request.error.code)
        ) {
          Sentry.captureMessage(request.error.message);
        }

        popup.render({
          title: translate('Please Refresh'),
          message: translate(
            'Your latest changes might not have been saved. Please refresh the page.'
          ),
          buttonText: translate('Refresh'),
          buttonClicked: () => {
            window.location.reload();
          },
        });
      }
    }, 2500);

    errorFunction(data, request);
  };

  return Backbone.Model.prototype.save.call(model, attributes, options);
};

module.exports = offlineStorage;
