class OAuthModel {
  constructor() {
    this.clientId = null;
    this.state = null;
    this.redirectUri = null;
    this.responseType = null;
  }

  setParameters(clientId, state, redirectUri, responseType) {
    this.clientId = clientId;
    this.state = state;
    this.redirectUri = redirectUri;
    this.responseType = responseType;
  }

  isValid() {
    return this.clientId && this.state && this.redirectUri && this.responseType;
  }

  getQueryParamString() {
    return `?client_id=${this.clientId}&state=${this.state}&redirect_uri=${this.redirectUri}&response_type=${this.responseType}`;
  }
}

module.exports = new OAuthModel();
