import * as React from 'react';

import CloseIcon from '../../../shared/icons/CloseIcon';

// TODO-FUTURE Build these strings into translator. Potato support for JSX?
export class ConnectionStatusView extends React.Component {
  constructor(props) {
    super(props);
    this.onDismissClick = this.onDismissClick.bind(this);
  }

  render() {
    if (!this.props.showStatus) {
      return null;
    }

    if (this.props.connectionStatus === 'connected') {
      return (
        <div className="connectionBar connected">
          <div className="icon" />
          <span className="mainText">You’re back online!</span>
          {this.props.user && (
            <span className="subText">
              {this.props.user.isTeacher()
                ? 'Missing some results?'
                : 'Still waiting after your teacher has started?'}{' '}
              Try{' '}
              <a className="link" onClick={this.onRefreshClick}>
                refreshing
              </a>
              .
            </span>
          )}
          <a className="dismiss link" onClick={this.onDismissClick}>
            <CloseIcon />
          </a>
        </div>
      );
    } else if (this.props.connectionStatus === 'disconnected') {
      return (
        <div className="connectionBar disconnected">
          <div className="icon" />
          <span className="mainText">You’re currently offline.</span>
          <span className="subText">
            Please check your internet connection.
          </span>
        </div>
      );
    } else if (this.props.connectionStatus === 'reconnecting') {
      return (
        <div className="connectionBar reconnecting">
          <span className="mainText">Trying to reconnect…</span>
        </div>
      );
    }

    return null;
  }

  onDismissClick() {
    this.props.onDismiss();
  }

  onRefreshClick() {
    window.location.reload();
  }
}
