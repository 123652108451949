import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { PlanType } from '@showbie-socrative/socrative-utils';

import { setFeaturePermissions } from '~teacher/util/proCheck/setFeaturePermissions';

import { Messenger } from '../integrations/shared/messenger/Messenger';

require('../../shared/less/integrations.less');
require('../src/main.scss');
require('extensions');
require('../routers/IntegrationsRouter');

let Backbone = require('backbone');
Backbone.history.start();

// Temp hotfix to set integration users to Pro
setFeaturePermissions({
  plan: window.is_integration ? PlanType.Pro : PlanType.Free,
  userVersion: 1,
});

window.iOSMessageReceived = new Messenger().handleiOSMessage;
