import platform from 'Platform';

const listeners = {};
export class Messenger {
  handleiOSMessage(message) {
    console.log(message);
    Messenger.handleMessage(message);
  }

  handleWebMessage(event) {
    console.log(event);

    if (event && event.origin === window.post_message_origin && event.data) {
      Messenger.handleMessage(event.data);
    } else {
      console.error('Invalid message', event);
    }
  }

  static handleMessage(message) {
    if (message && message.type) {
      try {
        let key = message.type.split('.')[0];
        if (listeners[key]) {
          for (let i = listeners[key].length - 1; i >= 0; i--) {
            let item = listeners[key][i];
            item.callback(message);

            // Remove the item if it is single use
            if (item.isSingleUse) {
              listeners[key].splice(i, 1);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  send(type, message = {}, isError = false) {
    let fullPayload = {
      type: type,
      payload: message,
    };

    if (isError) {
      fullPayload['error'] = true;
    }

    console.log(fullPayload);

    try {
      if (platform.isIOSView()) {
        window.webkit.messageHandlers.socrative.postMessage(fullPayload);
      } else {
        console.log(window.post_message_origin);
        window.parent.postMessage(fullPayload, window.post_message_origin);
      }
    } catch (err) {
      console.error(err);
    }
  }

  subscribe(namespace, callback, isSingleUse = false) {
    let item = { callback: callback, isSingleUse: isSingleUse };
    listeners[namespace]
      ? listeners[namespace].push(item)
      : (listeners[namespace] = [item]);
  }
}
