import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { RoomsView } from '../components/RoomsView';

export class RoomsController {
  constructor(router) {
    this.router = router;
  }

  viewRooms() {
    ReactDOM.render(
      <RoomsView router={this.router} />,
      document.getElementById('main-content')
    );
  }
}
