import * as React from 'react';

export default class LinkIcon extends React.Component {
  render() {
    let { className } = this.props;

    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
      >
        <path d="M7.179 8.379c2.265-2.265 5.97-2.232 8.275.073a5.956 5.956 0 0 1 1.57 2.753l-1.32 1.32a4.435 4.435 0 0 0-1.425-3.123c-1.742-1.626-4.414-1.588-6.04.038l-3.5 3.5c-1.662 1.662-1.665 4.418.073 6.155 1.737 1.738 4.493 1.735 6.154.074l.872-.872c.736.088 1.48.06 2.205-.084l-2.016 2.017c-2.265 2.264-5.97 2.232-8.276-.074a5.96 5.96 0 0 1-.112-.115c-2.194-2.312-2.19-5.933.038-8.161L7.18 8.379z" />
        <path d="M11.88 3.677c2.265-2.265 5.97-2.232 8.276.074s2.338 6.011.073 8.276l-3.5 3.501c-2.266 2.265-5.97 2.232-8.277-.074a5.95 5.95 0 0 1-1.57-2.752l1.321-1.32a4.434 4.434 0 0 0 1.31 3.012c1.738 1.737 4.493 1.735 6.155.073l3.5-3.5c1.662-1.662 1.665-4.418-.073-6.155a4.538 4.538 0 0 0-.115-.111c-1.742-1.626-4.414-1.588-6.04.037l-.871.872a7.016 7.016 0 0 0-2.205.084l2.016-2.017z" />
      </svg>
    );
  }
}
