/**
 * This module provides a central location for utility functions.
 * It should be used sparingly. Its main purpose is to eliminate
 * duplicate code when a simple, and preferably synchronous,
 * function is needed by different modules.
 */

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

const _ = require('underscore');
const platform = require('./Platform');
const request = require('./Request');

class Utils {
  constructor() {
    this.chars = ['&', '<', '>', '"', "'", '`', '!', '@', '%', '=', '{', '}'];
    this.escapedChars = [
      '&amp;',
      '&lt;',
      '&gt;',
      '&quot;',
      '&#039;',
      '&#96;',
      '&#33;',
      '&#64;',
      '&#37;',
      '&#61;',
      '&#123;',
      '&#125;',
    ];
    this.topPosition = null; // Top position of the scroll bar before hiding it for page blur.
  }

  /**
   * Historically, images uploaded by teachers were referenced by their raw
   * location in Amazon S3 (e.g. s3.amazonaws.com). This function converts
   * image URLs so they can be referenced via uploads.socrative.com.
   * @param oldUrl The old image URL
   * @returns {string|null} The new image URL or null if the conversion failed
   */
  convertImageUrl(oldUrl) {
    let newUrl = null;
    let file = /https?:\/\/(.*?)\/socrative\/(.*)$/.exec(oldUrl); // Check for a 'socrative' folder.

    if (file && file[1]) {
      switch (file[1]) {
        /* Production URLs */
        case 's3.amazonaws.com':
          newUrl = `https://uploads.socrative.com/${file[2]}`;
          break;
        case 'socrative.s3.amazonaws.com':
          newUrl = `https://uploads.socrative.com/socrative/${file[2]}`;
          break;
        /* Dev URL */
        case 'socrative-static-rob.s3.amazonaws.com':
          newUrl = `https://rob-assets.socrative.com/socrative/${file[2]}`;
          break;
      }
    } else {
      file = /.*\/(.*)$/.exec(oldUrl); // Extract just the file name from the URL.
      if (file && file[1]) {
        newUrl = `https://uploads.socrative.com/${file[1]}`;
      }
    }
    return newUrl;
  }

  /**
   * Truncate a string at a max length, append an ellipsis, and return the result.
   * If the string length is less than or equal to the maximum, the original
   * string is returned.
   * @param {string} str
   * @param {number} max
   * @returns {string} The ellipsified string
   */
  ellipsify(str, max) {
    return str.length > max ? str.substr(0, max) + '...' : str;
  }

  /**
   * Blur the background behind popups. Used by all popups, including
   * the report settings, terms, and student feedback/results.
   * Also make the page overflow hidden to eliminate page scrolling.
   * @param {object} popup The DOM element that contains the popup.
   */
  blurPage(popup) {
    if (popup && platform.isIosApp) {
      // Strange hack that fixes SOC-1573 and SOC-1604 by forcing WebKit to repaint (see http://stackoverflow.com/questions/3485365)
      popup.style.display = 'inline-block';

      popup.offsetHeight;

      popup.style.display = 'block';
    }

    let pageContainer = document.getElementById('page-container');
    const page = document.getElementById('body');

    if (!pageContainer) {
      pageContainer = document.getElementById('student-page-container');
    }

    if (pageContainer) {
      pageContainer.style.webkitFilter = 'blur(1px)';
      pageContainer.style.filter = 'blur(1px)';
    }

    if (page) {
      // Hide the scroll bar and disable scrolling on the page. Also prevent calculating the top position on modal re-render.
      if (!this.topPosition) {
        this.topPosition =
          window.pageYOffset !== undefined
            ? window.pageYOffset
            : (
                document.documentElement ||
                document.body.parentNode ||
                document.body
              ).scrollTop;
        page.style.position = 'fixed';
        page.style.top = '-' + this.topPosition + 'px';
      }
    }
  }

  unblurPage() {
    let pageContainer = document.getElementById('page-container');
    const page = document.getElementById('body');

    if (!pageContainer) {
      pageContainer = document.getElementById('student-page-container');
    }

    if (pageContainer) {
      pageContainer.style.webkitFilter = '';
      pageContainer.style.filter = '';
    }

    if (page) {
      let top = page.style.top;
      top = top.replace(/-|p|x/g, '');

      page.style.position = '';
      page.style.top = '';

      document.body.scrollTop = top;
      document.documentElement.scrollTop = top;

      this.topPosition = null;
    }
  }

  /**
   * Upload a file to Socrative's production S3 bucket.
   * @param {EventTarget} event The DOM event containing the file
   * @param {Object}      options An object containing optional success, error, and progress callbacks
   */
  upload(event, options) {
    const file = event.target.files[0];

    // First call our own API to get the upload data required by Amazon.
    request.get({
      url: `${
        window.backend_host
      }/media/api/get-s3-upload-data/?ct=${file.type || 'image/*'}`,
      success: function(data) {
        const formData = new window.FormData();
        formData.append('acl', 'public-read');
        formData.append('Content-Type', file.type);
        formData.append('key', data.key);
        formData.append('policy', data.policy);
        formData.append('x-amz-algorithm', 'AWS4-HMAC-SHA256');
        formData.append(
          'x-amz-credential',
          data.access + '/' + data.uploadDate + '/us-east-1/s3/aws4_request'
        );
        formData.append('x-amz-date', data.amzDate);
        formData.append('x-amz-signature', data.signature);
        formData.append('file', file);

        // Now upload the file to S3.
        const host = 'https://uploads.socrative.com/';
        request.post({
          url: host,
          data: formData,
          progress: function(event) {
            if (options.progress) {
              options.progress(event);
            }
          },
          success: function() {
            const fileUrl = host + data.key; // The fully qualified URL of the uploaded file.
            if (_.isFunction(options.success)) {
              options.success(fileUrl);
            }
          },
          error: function() {
            if (_.isFunction(options.error)) {
              options.error();
            }
          },
        });
      },
      error: function() {
        // Error getting upload data from our API.
        if (_.isFunction(options.error)) {
          options.error();
        }
      },
    });
  }

  /**
   * Encode a URI component. Taken from the Mozilla Developer Network:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
   * @param str The string to encode
   * @returns {string} The encoded string
   */
  encodeForUrl(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  /**
   * Convert the first letter of a string to uppercase.
   * @param {string} str The string to capitalize
   * @returns {string} The capitalized string
   */
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // Escape and unescape the content in question bodies and answers.
  unescape(content) {
    if (content) {
      _.each(this.escapedChars, (value, index) => {
        content = content.replace(new RegExp(value, 'g'), this.chars[index]);
      });
    }
    return content;
  }

  escape(content) {
    if (content) {
      _.each(this.chars, (value, index) => {
        content = content.replace(
          new RegExp(value, 'g'),
          this.escapedChars[index]
        );
      });
    }
    return content;
  }

  /**
   * Convert a number to its corresponding letter in the alphabet (1 => A, 2 => B). If the
   * number is larger than 26, multiple letters will be returned (27 => AA, 28 => AB).
   * @param {number} number The number to convert
   */
  convertToLetter(number) {
    let result = '';

    if (number > 26) {
      const baseChar = 'A'.charCodeAt(0);

      do {
        number -= 1;
        result = String.fromCharCode(baseChar + (number % 26)) + result;
        number = (number / 26) >> 0;
      } while (number > 0);
    } else {
      result = String.fromCharCode('A'.charCodeAt(0) + number - 1);
    }

    return result;
  }

  syncTimezone(value) {
    const date = new Date(value * 1000);

    if (date.getTimezoneOffset() > 0) {
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Make sure dates do not fall behind due to time zone.
    }

    return date;
  }

  escapeHtml(text) {
    if (text && text.length) {
      text = text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
      text = text
        .replace(/\t/g, '&emsp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#x27;');
    }
    return text;
  }

  formatDate(date) {
    if (!date) {
      return '';
    }

    const monthNames = [
      translate('January'),
      translate('February'),
      translate('March'),
      translate('April'),
      translate('May'),
      translate('June'),
      translate('July'),
      translate('August'),
      translate('September'),
      translate('October'),
      translate('November'),
      translate('December'),
    ];

    return `${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  }

  formatPrice(amountInPennies) {
    let formattedPrice = `$${amountInPennies / 100}`;

    if (/\.\d$/.test(formattedPrice)) {
      formattedPrice += '0';
    } else if (!/\./.test(formattedPrice)) {
      formattedPrice += '.00';
    }

    return formattedPrice;
  }

  /**
   * Convert an object's property names from snake_case to camelCase.
   * @param {object} object The target object
   */
  propsToCamelCase(object) {
    for (const property in object) {
      // eslint-disable-next-line no-prototype-builtins
      if (object.hasOwnProperty(property) && /_/g.test(property)) {
        const tokens = property.split(/_+/).filter((token) => {
          return token.length >= 1;
        });
        if (tokens.length <= 1) {
          continue;
        }
        const first = tokens.shift().toLowerCase();
        const rest = tokens
          .map((token) => {
            return token
              .charAt(0)
              .toUpperCase()
              .concat(token.substring(1).toLowerCase());
          })
          .join('');
        const value = object[property];
        delete object[property];
        object[first.concat(rest)] = value;
      }
    }
  }

  /**
   * Convert an empty string to the null value. Historically we've stored NULL in
   * the database (instead of empty strings) for various character varying fields.
   * @param value {string} The string value to convert to null if empty
   * @returns {null|string}
   */
  convertEmptyStringToNull(value) {
    return value === '' ? null : value;
  }

  /* -------------------------- */
  /*   Question Mixin Methods   */
  /*                            */
  /*   getNewQuestionState()    */
  /*   questionHasTags()        */
  /*   questionHasContent()     */
  /*                            */
  /*   Delete these when edit   */
  /*   quiz is refactored.      */
  /* -------------------------- */

  /**
   * Return the state of a question: displaying, regular editing, or formatted editing.
   * @param {object} question The question whose state will be returned
   * @returns {string} The state of the question
   */
  getNewQuestionState(question) {
    let questionState = 'displayContainer';

    if (question.get('dirty') === true) {
      questionState = 'editContainer';
    } else {
      if (question.get('duplicate') === true) {
        question.unset('duplicate');

        if (
          this.questionHasContent(question) &&
          this.questionHasTags(question)
        ) {
          questionState = 'formatContainer';
        } else {
          questionState = 'editContainer';
        }
      }
    }

    return questionState;
  }

  /**
   * Check a question object for HTML tags.
   * @param {object} question The question to check for tags
   * @returns {boolean} true if the question has tags, false otherwise
   */
  questionHasTags(question) {
    function testRegex(string) {
      return (
        /<(?!p)([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>/i.test(string) ||
        // eslint-disable-next-line no-useless-escape
        /(<br\ ?\b[^>]*\/?>)/g.test(string)
      );
    }

    const text = question.get('question_text');

    if (text && testRegex(text)) {
      return true;
    }

    if (question.get('type').toUpperCase() === 'MC') {
      let hasTags = false;

      for (const answer of question.answers) {
        if (testRegex(answer.get('text'))) {
          hasTags = true;
          break;
        }
      }

      if (hasTags) {
        return true;
      }
    }

    return (
      question.get('explanation') && testRegex(question.get('explanation'))
    );
  }

  /**
   * Determine whether a question's text, answers, or explanation has any content.
   * @param {object} question The question to check for content
   * @returns {boolean} true if the question has content, false otherwise
   */
  questionHasContent(question) {
    if (question.get('question_text')) {
      return true;
    }

    if (question.get('type') !== 'TF') {
      let hasContent = false;

      for (const answer of question.answers) {
        if (answer.get('text')) {
          hasContent = true;
          break;
        }
      }

      if (hasContent) {
        return true;
      }
    }

    return question.get('explanation');
  }
}

module.exports = new Utils();
