'use strict';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

const validations = require('../Validations');
const request = require('../Request');
const Constants = require('../Constants');

class PasswordModel {
  constructor() {
    /* ---------- */
    /*   Client   */
    /* ---------- */
    this.creating = false; // Whether the teacher is creating a password because his account was created for him (false means he's resetting).
    this.errors = {
      // Collection of password input errors. Set errors/messages with the setError() helper method.
      email: { error: false, message: '' },
      password: { error: false, message: '' },
      password2: { error: false, message: '' },
    };
    this.password2 = ''; // The second password supplied by the teacher for password reset.

    /* ---------- */
    /*   Shared   */
    /* ---------- */
    this.email = ''; // The email supplied by the teacher (for receiving the password reset email).
    this.password = ''; // The first password supplied by the teacher for password reset.
    this.token = ''; // The token from the password reset URL.
  }

  /* --------------- */
  /*   Validations   */
  /* --------------- */

  isValidEmail() {
    return validations.isValidEmail(this.email);
  }

  isPasswordMatch() {
    return this.password === this.password2;
  }

  isValidPassword() {
    return validations.isValidPassword(this.password);
  }

  isValidPassword2() {
    return validations.isValidPassword(this.password2);
  }

  isValidToken() {
    let pattern = new RegExp(`^[0-9a-f]{${Constants.PASSWORD_TOKEN_LENGTH}}$`);
    return pattern.test(this.token);
  }

  /* ------------------ */
  /*   Helper Methods   */
  /* ------------------ */

  setError(input, error, message) {
    this.errors[input].error = error;
    this.errors[input].message = message || '';
  }

  /* ------------------- */
  /*   Client Requests   */
  /* ------------------- */

  getResetLink(callback) {
    request.post({
      url: `${window.teacher_service}/v3/passwords/forgot`,
      data: {
        email: this.email,
      },
      success: () => {
        callback(true); // eslint-disable-line
      },
      error: (response) => {
        if (response.error === Constants.USER_NOT_FOUND) {
          this.setError(
            'email',
            true,
            translate('No account with that email was found.')
          );
        } else {
          this.setError(
            'email',
            true,
            translate('An unknown error occurred. Please try again later.')
          );
        }

        callback();
      },
    });
  }

  submitNewPassword(callback) {
    request.post({
      url: `${window.teacher_service}/v3/passwords/reset`,
      data: {
        password: this.password,
        token: this.token,
      },
      success: () => {
        callback();
      },
      error: (response) => {
        callback(response.error);
      },
    });
  }
}

module.exports = PasswordModel;
