import IntercomService from '../../clients/app/intercom/IntercomService';

const Backbone = require('backbone');
const _ = require('underscore');
const Question = require('../../clients/app/teacher/edit-quiz/LegacyQuestionModel');
const offlineStorage = require('offline-storage');
const user = require('user');
const activity = require('activity');

const Quiz = Backbone.Model.extend({
  urlRoot: function () {
    return window.teacher_service + '/quizzes/';
  },

  initialize: function (options) {
    if (this.has('questions')) {
      this.set('isTeacher', user.isTeacher());
      this.questions = [];

      _.each(this.get('questions'), (q) => {
        const newQ = new Question(q);
        newQ.set('quiz_id', this.get('id'));
        this.questions.push(newQ);
      });

      let shuffleQuestions = false;
      try {
        shuffleQuestions = activity.get('settings').random_questions;
      } catch (error) {
        console.error('Failed to parse activity for settings');
      }

      if (!this.get('isTeacher') && shuffleQuestions) {
        this.questions = _.shuffle(this.questions);
      } else {
        this.questions = _.sortBy(this.questions, function (q) {
          return q.get('order');
        });
      }

      // When a quiz is first initialized with no questions, it is a draft
      this.isDraftQuiz = !this.questions.length;

      this.unset('questions');
    }

    if (this.has('standard')) {
      this.standard = {
        subject:
          this.get('standard')['subject_id'] ||
          this.get('standard')['subjectId'],
        core: this.get('standard')['core_id'] || this.get('standard')['coreId'],
        class:
          this.get('standard')['grade_id'] || this.get('standard')['gradeId'],
        standard: {
          id:
            this.get('standard')['standard_id'] ||
            this.get('standard')['standardId'],
          name: this.get('standard')['name'],
          short_description: this.get('standard')['description'],
        },
      };

      this.unset('standard');
    } else {
      this.standard = null;
    }
  },

  getStandardName: function () {
    if (
      this.standard &&
      this.standard.standard &&
      this.standard.standard.name
    ) {
      return this.standard.standard.name;
    }
    return '';
  },

  toJSON: function (includeChildren) {
    const json = Backbone.Model.prototype.toJSON.call(this);

    if (!includeChildren && !_.isUndefined(json.questions)) {
      delete json.questions;
    } else if (includeChildren) {
      json.questions = [];
      _.each(this.questions, function (q) {
        json.questions.push(q.toJSON(includeChildren));
      });
    }

    if (this.standard && this.standard.complete) {
      // recreate the standard object to send it to the server
      json.standard = {
        subject_id: parseInt(this.standard['subject']),
        core_id: parseInt(this.standard['core']),
        grade_id: parseInt(this.standard['class']),
        standard_id: parseInt(this.standard.standard['id']),
        name: String(this.standard.standard['name']),
        description: String(this.standard.standard['short_description']),
      };
    } else {
      delete json.standard;
    }

    return json;
  },

  save: function (attributes, options) {
    this.unset('questions');
    if (this.isDraftQuiz) {
      IntercomService.event('create-quiz');
      this.isDraftQuiz = false;
    }

    if (this.has('dirty') && this.get('dirty')) {
      this.unset('dirty');
      return offlineStorage.save(this, attributes, options);
    } else {
      return false;
    }
  },
});

module.exports = Quiz;
