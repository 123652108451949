import * as React from 'react';

export default class AlertIcon extends React.Component {
  render() {
    let color = this.props.color || '#ce0000';

    return React.createElement('svg', {
      className: this.props.className,
      width: '19px',
      height: '18px',
      viewBox: '0 0 19 18',
      dangerouslySetInnerHTML: {
        __html:
          '<path fill="' +
          color +
          '" d="M18.7,14.3L11.1,1.2c-1-1.7-3.3-1.7-4.2,0.1L0.3,14.4C-0.6,16.1,0.6,18,2.5,18h14.1C18.5,18,19.6,16,18.7,14.3z M16.6,16H2.5c-0.4,0-0.6-0.3-0.4-0.7L8.6,2.3c0.2-0.4,0.5-0.4,0.7,0l7.6,13.1C17.1,15.7,17,16,16.6,16z M10,7.1V11c0,0.6-0.4,1-1,1 s-1-0.4-1-1V7.1c0-0.6,0.4-1,1-1S10,6.6,10,7.1z M10,13.9c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1 C9.5,12.9,10,13.3,10,13.9z"/>',
      },
      onClick: () => {
        if (this.props.onClick) {
          this.props.onClick();
        }
      },
    });
  }
}
