'use strict';

import request from '../../../../shared/Request';
let utils = require('Utils');

class RenewalModel {
  constructor() {
    this.expirationDate = new Date(); // Date when the user's PRO status expires.
    this.type = ''; // One of the renewal type constants (e.g. Constants.PRO_ENDING).
  }

  setData(data) {
    this.type = data.type;
    this.show = data.show; // Whether the renewal notice should be displayed.
    this.id = data.id; // The id of the notification in the database.
    this.expirationDate = utils.syncTimezone(data.expires_at);
  }

  closeBanner(callback) {
    this.show = false;

    request.post({
      url: `${window.backend_host}/lecturers/api/notifications/postpone/`,
      data: {
        id: this.id,
      },

      // TODO .... By design the Request module requires success and error callbacks.
      //           We should always handle the results of HTTP requests! These are
      //           placeholders until the renewal banner has a better design:

      success: () => {},
      error: () => {},

      complete: function () {
        if (callback) {
          callback();
        }
      },
    });
  }

  get expirationDateFormatted() {
    return utils.formatDate(this.expirationDate);
  }

  get showRenewal() {
    return this.show && this.type !== '';
  }

  clear() {
    this.type = '';
    this.show = false;
  }
}

module.exports = new RenewalModel();
