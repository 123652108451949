import * as React from 'react';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

import Button from './buttons/Button';
import FormField from './form-field/FormField';

const request = require('../../../../shared/Request');
const Constants = require('Constants');
const validations = require('Validations');

const ErrorPane = ({ children }) => {
  if (children) {
    return (
      <div className="error-pane" style={{ marginTop: '3rem' }}>
        <div className="error-pane__icon">
          <svg width="19px" height="18px" viewBox="0 0 19 18">
            <path d="M18.7,14.3L11.1,1.2c-1-1.7-3.3-1.7-4.2,0.1L0.3,14.4C-0.6,16.1,0.6,18,2.5,18h14.1C18.5,18,19.6,16,18.7,14.3z M16.6,16H2.5c-0.4,0-0.6-0.3-0.4-0.7L8.6,2.3c0.2-0.4,0.5-0.4,0.7,0l7.6,13.1C17.1,15.7,17,16,16.6,16z M10,7.1V11c0,0.6-0.4,1-1,1 s-1-0.4-1-1V7.1c0-0.6,0.4-1,1-1S10,6.6,10,7.1z M10,13.9c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1 C9.5,12.9,10,13.3,10,13.9z" />
          </svg>
          <strong>{translate('Error')}</strong>
        </div>
        <div className="error-pane__message">{children}</div>
      </div>
    );
  }

  return null;
};

export class LoginView extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      touched: {
        email: false,
        password: false,
      },
      fieldErrors: {
        email: '',
        password: '',
      },
      emailError: false,
      passwordError: false,
      saving: false,
    };
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  render() {
    const shouldIndicateError = (field) => {
      let hasError = errors[field];
      let shouldShow = this.state.touched[field];

      return hasError ? shouldShow : false;
    };

    let errors = this.validateClientSide(this.state.email, this.state.password);

    return (
      <div className="content-container">
        <form onSubmit={this.handleLogin}>
          <div className="content-header">
            <h1 style={{ maxWidth: '67%' }}>
              {translate('Please sign-in with your Socrative teacher account.')}
            </h1>
            <div className="content-header__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="47" height="60">
                <path
                  fill="#ff602b"
                  fillRule="evenodd"
                  d="M39.037 26.536h-8.646c-.976 0-1.879.513-2.367 1.346L23.7 35.267a2.658 2.658 0 0 0 0 2.693l4.323 7.383a2.741 2.741 0 0 0 2.367 1.346h8.646c.976 0 1.876-.513 2.365-1.346l4.324-7.383a2.658 2.658 0 0 0 0-2.693l-4.324-7.385a2.738 2.738 0 0 0-2.365-1.346m0-26.536h-8.646c-.976 0-1.879.513-2.367 1.346L23.7 8.73a2.656 2.656 0 0 0 0 2.693l4.323 7.384a2.741 2.741 0 0 0 2.367 1.346h8.646c.976 0 1.876-.513 2.365-1.346l4.324-7.384a2.656 2.656 0 0 0 0-2.693l-4.324-7.384A2.738 2.738 0 0 0 39.037 0M15.702 39.847H7.055c-.975 0-1.878.513-2.366 1.346L.366 48.577a2.654 2.654 0 0 0 0 2.691l4.323 7.386A2.743 2.743 0 0 0 7.055 60h8.647c.976 0 1.878-.514 2.366-1.346l4.323-7.386a2.654 2.654 0 0 0 0-2.69l-4.323-7.385a2.74 2.74 0 0 0-2.366-1.346m6.689-15.114l-4.323 7.384a2.74 2.74 0 0 1-2.366 1.347H7.055a2.742 2.742 0 0 1-2.366-1.347L.366 24.733a2.658 2.658 0 0 1 0-2.693l4.323-7.383a2.742 2.742 0 0 1 2.366-1.347h8.647a2.74 2.74 0 0 1 2.366 1.347l4.323 7.383c.488.833.488 1.86 0 2.693"
                />
              </svg>
            </div>
          </div>

          <div className="content-main">
            <ErrorPane>{this.state.emailError}</ErrorPane>

            <FormField
              className={shouldIndicateError('email') ? 'has-error' : ''}
              id="email"
              type="email"
              label={translate('Email')}
              required
              errorMessage={this.state.fieldErrors.email}
              changeAction={(event) => this.onEmailChange(event.target.value)}
              blurAction={this.handleBlur('email')}
              styles={{ marginTop: this.state.emailError ? '1rem' : '3rem' }}
            />
            <FormField
              className={shouldIndicateError('password') ? 'has-error' : ''}
              id="password"
              type="password"
              label={translate('Password')}
              required
              errorMessage={this.state.fieldErrors.password}
              changeAction={(event) =>
                this.onPasswordChange(event.target.value)
              }
              blurAction={this.handleBlur('password')}
              styles={{ marginTop: '1rem' }}
            />
            <a
              href="/forgot-password"
              style={{
                display: 'inline-block',
                margin: '0.5rem 0',
              }}
              onClick={(event) => this.onForgotPassword(event)}
            >
              {translate('Forgot your password?')}
            </a>
          </div>

          <div className="content-footer">
            <Button
              disabled={this.state.saving}
              type="submit"
              appearance="primary"
              size="large"
              styles={{
                width: '100%',
                marginTop: '1rem',
              }}
            >
              {translate('Sign In')}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  onEmailChange(email) {
    this.setState({ email: email });
  }

  onPasswordChange(password) {
    this.setState({ password: password });
  }

  onForgotPassword(event) {
    event.preventDefault();
    this.props.router.navigate('forgot-password', { trigger: true });
  }

  handleBlur(field) {
    let self = this;
    return function (event) {
      self.setState({
        touched: Object.assign({}, self.state.touched, { [field]: true }),
        fieldErrors: Object.assign({}, self.state.fieldErrors, {
          [field]: event.target.validationMessage,
        }),
      });
    };
  }

  handleLogin(e) {
    // Prevent form redirection
    e.preventDefault();

    if (!this.validate() || this.state.saving) {
      return;
    }

    if (!this.props.model.isValid()) {
      this.props.router.errorEvent = 'Invalid query params';
      this.props.router.navigate('error', { trigger: true });
      return;
    }

    let url = `${
      window.teacher_service
    }/oauth/login${this.props.model.getQueryParamString()}`;
    this.setState({ saving: true });

    request.post({
      url: url,
      data: { email: this.state.email, password: this.state.password },
      success: this.onLoginSuccess,
      error: (response) => {
        if (response.error) {
          const error = response.error;

          if (
            error === Constants.INVALID_EMAIL ||
            error === Constants.USER_NOT_FOUND ||
            error === Constants.EMAIL_MISSING ||
            error === Constants.PASSWORD_MISSING ||
            error === Constants.LOGIN_FAILED ||
            error === Constants.STRING_IS_TOO_LONG
          ) {
            this.setState({
              emailError: translate('Your email or password is incorrect.'),
            });
          } else if (error === Constants.ACCOUNT_SUSPENDED) {
            this.setState({
              emailError: translate(
                'Your account is suspended. Please contact support for details.'
              ),
            });
          } else if (error === Constants.ACCOUNT_BLOCKED_FOR_FIVE_MINUTES) {
            this.setState({
              emailError: translate(
                'Your account has been blocked for 5 minutes. Please contact support for details.'
              ),
            });
          } else if (error === Constants.ACCOUNT_BLOCKED_FOR_ONE_HOUR) {
            this.setState({
              emailError: translate(
                'Your account has been blocked for 1 hour. Please contact support for details.'
              ),
            });
          } else if (error === Constants.ACCOUNT_BLOCKED_FOR_ONE_DAY) {
            this.setState({
              emailError: translate(
                'Your account has been blocked for 1 day. Please contact support for details.'
              ),
            });
          } else if (error === Constants.IP_BLOCKED_FOR_ONE_DAY) {
            this.setState({
              emailError: translate(
                'Your IP address has been blocked. Please contact support for details.'
              ),
            });
          }

          this.setState({ saving: false });
        }
      },
    });
  }

  onLoginSuccess() {
    this.setState({ saving: false });
    this.props.router.navigate('authorize', { trigger: true, replace: true });
  }

  validateClientSide(email, password) {
    return {
      email: email.length === 0 || this.state.fieldErrors.email,
      password: password.length === 0,
    };
  }

  validate() {
    let email = this.state.email;
    let password = this.state.password;
    let isValid = false;

    if (!validations.isValidEmail(email)) {
      this.setState({ emailError: translate('Please enter a valid email.') });
    } else if (!password || password.length < 1) {
      // Legacy Password check
      this.setState({ passwordError: true });
    } else {
      isValid = true;
      this.setState({ emailError: false, passwordError: false });
    }

    return isValid;
  }
}
