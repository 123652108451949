import { clearAmplitudeUserSession } from '~analytics/amplitude';

import reduxStore from '../../clients/app/src/store';
import {
  setBetaFlags,
  setIsTeacher,
  setTeacherId,
} from '../../clients/app/src/teacher/slices/teacher/teacherSlice';
import {
  getRandomSeedFromCookie,
  getRandomSeedFromHour,
  getRandomSeedFromStudentId,
} from '../../clients/app/src/util/seed';

/* eslint-disable @typescript-eslint/no-var-requires */
const Backbone = require('backbone');
const _ = require('underscore');
const room = require('room');
const account = require('TeacherModel').account;
const request = require('../Request');
const messaging = require('Messaging');
const store = require('store');
const Cookies = require('js-cookie');
const COOKIE_NAME = 'com.socrative.app.user_data_';
const COOKIE_ATTRS = {
  expires: 7,
  path: '/',
}; // The same cookie attributes must be used in set() and remove()
export const SHARED_WORKSPACES_VERSION = 2;

const User = Backbone.Model.extend({
  initialize: function () {
    this.on('change:mode', this.reduxIsTeacherMapper);
    this.on('change:id', this.reduxTeacherIdMapper);
    this.on('change:beta_flags', this.reduxBetaFlagMapper);
  },
  reduxIsTeacherMapper: function (model, value) {
    const isTeacher = value === 'teacher';
    reduxStore.dispatch(setIsTeacher({ isTeacher: isTeacher }));
  },
  reduxTeacherIdMapper: function (model, value) {
    reduxStore.dispatch(setTeacherId({ teacherId: value }));
  },
  reduxBetaFlagMapper: function (model, value) {
    reduxStore.dispatch(setBetaFlags({ betaFlags: value }));
  },
  setModeSilent: function (mode) {
    this.set(
      {
        mode: mode,
      },
      {
        silent: true,
      }
    );
    this.reduxIsTeacherMapper(null, mode);
  },

  setId: function (value) {
    this.set('id', value);
  },
  getId: function () {
    return this.get('id');
  },

  setHmac(value) {
    this.set('hmac', value);
  },

  getHmac() {
    return this.get('hmac');
  },

  setBetaFlags(flags) {
    if (!flags) flags = {};

    this.set('beta_flags', flags);
  },

  getBetaQuizVersion() {
    let betaFlags = this.get('beta_flags');
    return betaFlags ? betaFlags['quiz_editor_version'] : 1;
  },

  getBetaQuizzesVersion() {
    const betaFlags = this.get('beta_flags');
    return betaFlags ? betaFlags['quizzes_version'] : 1;
  },

  setEmail: function (value) {
    this.set('email', value);
  },

  getEmail: function () {
    return this.get('email');
  },

  setCountry: function (value) {
    this.set('country', value);
  },

  getCountry: function () {
    return this.get('country');
  },

  setMode: function (mode) {
    this.set('mode', mode);
  },

  getMode: function () {
    return this.get('mode');
  },

  setSharedSocNumber: function (value) {
    Cookies.set('sharedSocNumber', value, {
      expires: 1,
      path: '/',
    });
  },

  getSharedSocNumber: function () {
    return Cookies.get('sharedSocNumber');
  },

  unsetSharedSocNumber: function () {
    Cookies.remove('sharedSocNumber', {
      expires: 1,
      path: '/',
    });
  },

  setDataFromRoom: function (data) {
    if (data.created_by) {
      this.setId(data.created_by);
    }

    this.setComplete(data.complete_user);
    this.setMcUser(data.mastery_connect);
    this.setFirstName(data.first_name);
    this.setUserUuid(data.user_uuid);
    account.googleUser = data.google_user;
    this.setLanguage(data.lang);
    this.setHandraiseStatus(data.handraise);
  },

  setComplete: function (completeUser) {
    this.set('complete_user', completeUser);
  },

  isComplete: function () {
    return this.get('complete_user');
  },

  setMcUser: function (masteryConnect) {
    this.set('mastery_connect', masteryConnect);
  },

  isMcUser: function () {
    return this.get('mastery_connect');
  },

  setPremium: function (premium) {
    this.set('premium', premium);
  },

  wantsPro: function () {
    return this.get('wantsPro');
  },

  setWantsPro: function (value) {
    this.set('wantsPro', value);
  },

  setJoinWorkspaceCode: function (value) {
    Cookies.set('joinWorkspaceCode', value, {
      expires: 1,
      path: '/',
    });
  },

  getJoinWorkspaceCode: function () {
    return Cookies.get('joinWorkspaceCode');
  },

  unsetJoinWorkspaceCode: function () {
    Cookies.remove('joinWorkspaceCode', {
      expires: 1,
      path: '/',
    });
  },

  usedGetProLink: function () {
    return this.get('usedGetProLink');
  },

  setUsedGetProLink: function (value) {
    this.set('usedGetProLink', value);
  },

  setFirstName: function (firstName) {
    this.set('first_name', firstName);
  },

  getFirstName: function () {
    return this.get('first_name');
  },

  hasRosterName: function () {
    return this.has('last_name') && this.has('first_name');
  },

  getRosterName: function () {
    return this.get('last_name') + ', ' + this.get('first_name');
  },

  setUserUuid: function (userUuid) {
    this.set('user_uuid', userUuid);
  },

  getUserUuid: function () {
    return this.get('user_uuid');
  },

  getRandomSeed: function () {
    return (
      getRandomSeedFromStudentId(this.get('studentId')) ??
      getRandomSeedFromCookie() ??
      getRandomSeedFromHour()
    );
  },

  /**
   * @deprecated
   */
  setFirstTime: function (firstTime) {
    this.set('firstTime', firstTime);
  },

  /**
   * @deprecated
   */
  isFirstTime: function () {
    return this.get('firstTime');
  },

  /**
   * @deprecated
   */
  unsetFirstTime: function () {
    this.unset('firstTime');
  },

  /**
   * @deprecated
   */
  setFirstTimePro: function (firstTimePro) {
    this.set('firstTimePro', firstTimePro);
  },

  /**
   * @deprecated
   */
  isFirstTimePro: function () {
    return this.get('firstTimePro');
  },

  /**
   * @deprecated
   */
  unsetFirstTimePro: function () {
    this.unset('firstTimePro');
  },

  setAuthTokenAndMode: function (authToken, mode) {
    this.set({
      auth_token: authToken,
      mode: mode,
    });
  },

  setLanguage: function (language) {
    this.set('language', language);
    Cookies.set('socrative_lang', language, {
      path: '/',
    });
  },

  getLanguage: function () {
    let language = this.get('language');
    if (!language) {
      language = Cookies.get('socrative_lang');
    }

    return language;
  },

  setRoomName: function (value) {
    this.set('room', value);
  },

  getRoomName: function () {
    return this.get('room');
  },

  isTeacher: function () {
    return this.get('mode') === 'teacher';
  },

  isStudent: function () {
    return this.get('mode') === 'student';
  },

  setHandraiseStatus: function (value) {
    if (_.isBoolean(value)) {
      this.set('handraise_status', value);
    } else {
      if (value === 'disabled') {
        this.set('handraise_status', false);
      } else {
        this.set('handraise_status', true);
        // set student handraise to off when the teacher turned off and on the handraise
        this.setStudentHandraise(false);
      }
    }
  },

  getHandRaiseStatus: function () {
    return this.get('handraise_status');
  },

  setStudentHandraise: function (value) {
    this.set('handraise', value);
  },

  getStudentHandraise: function () {
    return this.get('handraise');
  },

  isQuizFolderNavOpen: function () {
    return this.get('quizFolderNavOpen');
  },

  setQuizFolderNavOpen: function (value) {
    this.set('quizFolderNavOpen', value);
  },

  getQuizSortColumn: function () {
    return this.get('quizSortColumn');
  },

  setQuizSortColumn: function (value) {
    this.set('quizSortColumn', value);
  },

  getQuizSortDirection: function () {
    return this.get('quizSortDirection');
  },

  setQuizSortDirection: function (value) {
    this.set('quizSortDirection', value);
  },

  isExportingScores: function () {
    return this.get('exportingScores');
  },

  setExportingScores: function (value) {
    this.set('exportingScores', value);
  },

  clearCookieData: function () {
    if (this.checkMode()) {
      if (store.enabled) {
        store.remove('localUser');
      } else {
        Cookies.set(COOKIE_NAME + this.get('mode'), null, COOKIE_ATTRS);
        Cookies.remove(COOKIE_NAME + this.get('mode'), COOKIE_ATTRS);
      }
    }
  },

  getCookieData: function () {
    if (this.checkMode()) {
      if (store.enabled) {
        let localUserData = store.get('localUser');
        if (localUserData) {
          this.set(localUserData);
          return true;
        }
      } else {
        let userData = null;
        try {
          userData = JSON.parse(Cookies.get(COOKIE_NAME + this.get('mode')));
        } catch (e) {
          console.log('user_data cookie not set...showing login screen');
        }
        if (userData) {
          user.set(userData);
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  },
});

let user = new User();

if (!user.has('quizFolderNavOpen')) {
  user.setQuizFolderNavOpen(true);
}

user.on('change', function () {
  if (user.checkMode()) {
    if (store.enabled) {
      store.set('localUser', user.toJSON());
    } else {
      let cookieData = JSON.stringify(user.toJSON());
      Cookies.set(COOKIE_NAME + user.get('mode'), cookieData, COOKIE_ATTRS);
    }
  }
});

/**
 * function to check if the user has the correct mode:
 *    teacher or student
 *
 * @returns {bool} true if it's one of those two
 */
user.checkMode = function () {
  if (
    this.has('mode') &&
    (this.get('mode') === 'teacher' || this.get('mode') === 'student')
  ) {
    return true;
  } else {
    return false;
  }
};

user.setName = function (displayname) {
  user.set('display_name', displayname);
};

user.clearRoom = function (callback) {
  request.post({
    url: `${window.backend_host}/rooms/api/clear/`,
    data: {
      room_name: room.get('name'),
    },
    success: () => {
      if (_.isFunction(callback)) {
        callback();
      }
    },
    error: (response) => {
      if (_.isFunction(callback)) {
        callback(response);
      }
    },
  });
};

user.logout = function (callback) {
  let data = {};
  if (this.isStudent()) {
    data.room_name = room.getRoomName();
  } else {
    clearAmplitudeUserSession();
  }

  request.post({
    url: `${window.backend_host}/users/api/logout/`,
    data: data,
    success: () => {
      // Get necessary properties before clearing the room and user.
      let roomName = room.getRoomName();
      if (!roomName) {
        roomName = this.get('room');
      }
      let isTeacher = this.isTeacher();

      room.clear();
      this.clearCookieData();
      this.clear();

      if (roomName) {
        roomName = roomName.toLowerCase();
        let channels = [`${roomName}-student`];
        if (isTeacher) {
          channels.push(`${roomName}-teacher`);
        }
        messaging.unsubscribe({
          channel: channels,
          callback: callback,
        });
      } else {
        if (callback) {
          callback();
        }
      }
    },
    error: (response) => {
      console.error('Error logging out:', response);
    },
  });
};

user.joinRoom = function (role, roomName, onSuccess) {
  room.join({
    role: role,
    roomname: roomName,
    success: function () {
      user.setRoomName(room.getRoomName().toLowerCase());
      if (_.isFunction(onSuccess)) {
        onSuccess();
      }
    },
  });
};

user.setRandomName = function () {
  if (user.has('user_uuid')) {
    user.set('user_input_name', 'Anon ' + user.get('user_uuid'));
  } else {
    user.set('user_input_name', 'Anon');
    console.warn("User doesn't have uuid: ", user.attributes);
  }
};

module.exports = user;
