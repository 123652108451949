'use strict';

const Constants = require('../Constants');

class FolderNavModel {
  constructor(rootName, folders) {
    this.rootFolder = {
      children: [],
      expanded: false,
      id: Constants.ROOT_FOLDER,
      level: 0,
      name: rootName,
      visible: true,
    };

    this.idFolderMap = new Map();

    for (let folder of folders) {
      folder.children = [];
      this.idFolderMap.set(folder.id, folder);
    }

    for (let child of this.idFolderMap.values()) {
      let parent = this.getFolder(child.parentId);
      parent.children.push(child);
    }

    // Sort the children of each folder after the
    // entire tree structure has been established.
    this.rootFolder.children.sort(this.sortChildren);

    for (let folder of this.idFolderMap.values()) {
      folder.children.sort(this.sortChildren);
    }
  }

  sortChildren(a, b) {
    const aLower = a.name.toLowerCase();
    const bLower = b.name.toLowerCase();

    if (aLower < bLower) {
      return -1;
    }

    if (aLower > bLower) {
      return 1;
    }

    return 0;
  }

  depthFirstPreOrder(folder, level, callback) {
    folder.level = level;

    if (level !== 0) {
      let parent = this.getFolder(folder.parentId);
      folder.visible = parent.visible && parent.expanded && !folder.deleted;
    }

    callback(folder);

    level++;

    for (let child of folder.children) {
      child.level = level;
      this.depthFirstPreOrder(child, level, callback);
    }
  }

  traverse(callback) {
    this.depthFirstPreOrder(this.rootFolder, 0, callback);
  }

  getFolder(id) {
    return this.idFolderMap.get(id) || this.rootFolder;
  }

  addFolder(folder, parentId) {
    this.idFolderMap.set(folder.id, folder);
    let parent = this.getFolder(parentId);
    folder.level = parent.level + 1;
    parent.children.push(folder);
    parent.children.sort(this.sortChildren);
  }

  toggleExpanded(id) {
    let folder = this.getFolder(id);
    folder.expanded = !folder.expanded;
  }

  expandAllAncestors(id) {
    let folder = this.getFolder(id);

    while (folder.id !== Constants.ROOT_FOLDER) {
      let parent = this.getFolder(folder.parentId);
      parent.expanded = true;
      folder = parent;
    }
  }

  getChildren(parentId) {
    if (parentId === Constants.TRASH) {
      let deleted = [];

      for (let folder of this.idFolderMap.values()) {
        if (folder.deleted) {
          deleted.push(folder);
        }
      }

      return deleted;
    } else {
      return this.getFolder(parentId).children || [];
    }
  }

  renameFolder(id, newName) {
    const folder = this.getFolder(id);
    const parent = this.getFolder(folder.parentId);

    folder.name = newName;
    folder.date = new Date();
    parent.children.sort(this.sortChildren);
  }

  moveFolder(id, parentId) {
    const folder = this.getFolder(id);
    const oldParent = this.getFolder(folder.parentId);
    const oldChildren = [];
    const newParent = this.getFolder(parentId);

    for (let child of oldParent.children) {
      if (child.id !== id) {
        oldChildren.push(child);
      }
    }

    oldParent.children = oldChildren;
    oldParent.children.sort(this.sortChildren);

    folder.parentId = parentId;
    newParent.children.push(folder);
    newParent.children.sort(this.sortChildren);
  }

  purgeChild(id) {
    const folder = this.getFolder(id);
    const parent = this.getFolder(folder.parentId);
    const children = [];

    for (let child of parent.children) {
      if (child.id !== id) {
        children.push(child);
      }
    }

    parent.children = children;
  }

  hasDeletedAncestor(folder) {
    let parent = this.getFolder(folder.parentId);

    while (parent.id !== Constants.ROOT_FOLDER) {
      if (parent.deleted) {
        return true;
      }

      parent = this.getFolder(parent.parentId);
    }

    return false;
  }
}

module.exports = FolderNavModel;
