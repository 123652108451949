import * as React from 'react';

import { Messenger } from '../shared/messenger/Messenger';

export class AppView extends React.Component {
  componentDidMount() {
    window.addEventListener('message', new Messenger().handleWebMessage, false);
  }

  render() {
    return (
      <div>
        <header
          id="header"
          className="sticky left-0 w-screen z-50 bg-white shadow-md"
        ></header>
        <div id="status-container" />
        <div id="main-content" />
        <div id="footer-container" />
      </div>
    );
  }
}
