import * as React from 'react';

import { loadClientTranslations } from '@showbie-socrative/socrative-utils/lib/translator/client';

import { configureAxiosForIntegration } from '../../src/api/configureAxiosForIntegration';
import * as MessengerTypes from '../shared/messenger/MessengerTypes';
import { PostMessenger } from '../shared/messenger/PostMessenger';

const request = require('../../../../shared/Request');
const Constants = require('Constants');

export class GatekeeperView extends React.Component {
  constructor() {
    super();
    this.state = { authenticated: false };
    this.onAuthenticateSuccess = this.onAuthenticateSuccess.bind(this);
  }

  componentDidMount() {
    this.authenticate();
  }

  render() {
    /*
     * Removing Spinner since it conflicts with client loading
     * indicators. Leaving structure markup here in case some kind
     * of interstitial content is shown in the future.
     */
    return <div />;
  }

  authenticate() {
    let url = `${window.teacher_service}/integration/auth/gatekeeper?token=${this.props.token}`;
    request.post({
      url: url,
      data: this.props.options,
      success: this.onAuthenticateSuccess,
      error: (response) => {
        console.error(response);
        PostMessenger.sendMessage(
          MessengerTypes.GENERAL.AUTHENTICATE,
          response,
          true
        );
        this.props.controller.handleError(response);
      },
    });
  }

  onAuthenticateSuccess(response) {
    try {
      // Save token and language to session storage
      window.sessionStorage.setItem(
        Constants.INTEGRATION_AUTH_SESSION_NAME,
        response.token
      );
      let language = response.language || 'en';
      window.sessionStorage.setItem(
        Constants.INTEGRATION_LANGUAGE_NAME,
        language
      );

      // Setup axios using integration auth mode
      configureAxiosForIntegration();

      let me = this;
      // Redirect after loading translations
      loadClientTranslations(language, function () {
        me.props.controller.handleRedirect(response);
      });
    } catch (error) {
      console.error(error);
      PostMessenger.sendMessage(
        MessengerTypes.GENERAL.AUTHENTICATE,
        error,
        true
      );
      this.props.controller.handleError(error);
    }
  }
}
