/*
    Socrative Dots - The animating dots displayed on click when a button's action results in an asynchronous operation.
 */

'use strict';

import * as React from 'react';

class Dots {
  constructor() {
    this.button = null; // The button in which the dots are currently animating.
    this.content = ''; // The content of the button before the dots began animating.
    this.dots = `<div class="spinner">
                         <div class="bounce1"></div>
                         <div class="bounce2"></div>
                         <div class="bounce3"></div>
                     </div>`;
    this.running = false; // Whether the animation is currently running.
  }

  start(buttonId) {
    let button = document.getElementById(buttonId);

    if (button === this.button && this.running) {
      return;
    }

    this.button = button;
    this.content = button.innerHTML;
    this.running = true;

    button.innerHTML = this.dots;
    button.disabled = true;
  }

  stop() {
    if (this.button) {
      this.button.innerHTML = this.content;
      this.button.disabled = false;
      this.button = null;
      this.content = '';
      this.running = false;
    }
  }

  getReactComponent() {
    return (
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    );
  }
}

export default new Dots();
