import * as React from 'react';

export default class RosterIcon extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
      >
        <g fillRule="evenodd">
          <path d="M8.5 3c-3.002.009-4.496 2.24-4.496 5.121 0 1.13.314 2.41.592 3.087.333.813.928 1.948.936 1.959.228.354.27.854.022 1.196-.102.142-.305.322-.721.535-.349.18-2.104.933-2.414 1.075-1.73.792-2.43 1.761-2.419 3.521.005.829.626 1.496 1.454 1.496H15.546c.828 0 1.449-.667 1.454-1.496.011-1.76-.689-2.729-2.419-3.521-.31-.142-2.065-.895-2.414-1.075-.416-.213-.619-.393-.721-.535-.248-.342-.206-.842.022-1.196.008-.011.603-1.146.936-1.959.278-.677.592-1.957.592-3.087C12.996 5.24 11.502 3.009 8.5 3zm0 1.5c2.607.011 2.996 2.273 2.996 3.621 0 .874-.247 1.95-.48 2.519a25.903 25.903 0 0 1-.848 1.777c-.543.894-.521 2.021.063 2.826.272.376.669.691 1.249.988.257.133.949.437 1.828.821.301.132.55.24.649.285 1.323.606 1.545 1.124 1.537 2.153H1.499l.001-.006c-.006-1.012.207-1.535 1.543-2.147.099-.045.348-.153.649-.285.879-.384 1.571-.688 1.828-.821.58-.297.977-.612 1.25-.988.582-.805.605-1.93.062-2.826a25.46 25.46 0 0 1-.848-1.777c-.233-.569-.48-1.645-.48-2.519 0-1.348.389-3.61 2.996-3.621z" />
          <path d="M13.943 14.601c.334-.15.64-.289.753-.348.318-.166.473-.306.551-.416.19-.267.158-.655-.017-.931a21.608 21.608 0 0 1-.715-1.523c-.212-.526-.453-1.521-.453-2.401 0-2.24 1.142-3.975 3.438-3.982 2.296.007 3.438 1.742 3.438 3.982 0 .88-.24 1.875-.453 2.401-.254.632-.71 1.515-.715 1.523-.175.276-.207.664-.017.931.078.11.233.25.551.416.267.14 1.61.726 1.846.836 1.323.615 1.86 1.369 1.85 2.738-.004.645-.479 1.163-1.112 1.163h-4.904a4.647 4.647 0 0 0-.338-1.5h4.843c-.043-.442-.233-.698-.972-1.042a80.306 80.306 0 0 0-.49-.219c-.7-.311-1.213-.54-1.419-.648-.488-.255-.84-.541-1.078-.875-.516-.726-.54-1.736-.067-2.537.098-.19.44-.871.631-1.346a5.796 5.796 0 0 0 .344-1.841c0-1.128-.336-2.474-1.938-2.482-1.602.008-1.938 1.354-1.938 2.482 0 .68.201 1.486.344 1.841.191.475.533 1.156.631 1.346.474.802.45 1.811-.068 2.538a2.684 2.684 0 0 1-.786.709 8.257 8.257 0 0 0-.686-.352 825.26 825.26 0 0 1-1.054-.463z" />
        </g>
      </svg>
    );
  }
}
