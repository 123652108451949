import * as React from 'react';

export default class CloseX extends React.Component {
  render() {
    let color = this.props.color || '#ffffff';

    return React.createElement('svg', {
      className: this.props.className,
      width: '17px',
      height: '18px',
      viewBox: '0 0 17 18',
      dangerouslySetInnerHTML: {
        __html:
          '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g transform="translate(-1175.000000, -155.000000)" stroke="' +
          color +
          '" stroke-width="2"><g transform="translate(0.000000, 133.000000)"><g transform="translate(1176.000000, 23.000000)"><path d="M14.375,0.615384615 L0,14.7692308"></path><path d="M0,1.23076923 L14.375,15.3846154"></path></g></g></g></g>',
      },
      onClick: (event) => {
        if (this.props.onClick) {
          this.props.onClick(event);
        }
      },
    });
  }
}
