import * as React from 'react';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

import PasswordModel from 'PasswordModel';

import Button from './buttons/Button';
import FormField from './form-field/FormField';

const request = require('../../../../shared/Request');
const validations = require('Validations');
const Constants = require('Constants');

const ErrorPane = ({ children }) => {
  if (children) {
    return (
      <div className="error-pane" style={{ marginTop: '3rem' }}>
        <div className="error-pane__icon">
          <svg width="19px" height="18px" viewBox="0 0 19 18">
            <path d="M18.7,14.3L11.1,1.2c-1-1.7-3.3-1.7-4.2,0.1L0.3,14.4C-0.6,16.1,0.6,18,2.5,18h14.1C18.5,18,19.6,16,18.7,14.3z M16.6,16H2.5c-0.4,0-0.6-0.3-0.4-0.7L8.6,2.3c0.2-0.4,0.5-0.4,0.7,0l7.6,13.1C17.1,15.7,17,16,16.6,16z M10,7.1V11c0,0.6-0.4,1-1,1 s-1-0.4-1-1V7.1c0-0.6,0.4-1,1-1S10,6.6,10,7.1z M10,13.9c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1 C9.5,12.9,10,13.3,10,13.9z" />
          </svg>
          <strong>{translate('Error')}</strong>
        </div>
        <div className="error-pane__message">{children}</div>
      </div>
    );
  }

  return null;
};

export class ForgotPasswordView extends React.Component {
  constructor() {
    super();

    this.state = {
      email: '',
      touched: {
        email: false,
      },
      fieldErrors: {
        email: '',
      },
      emailError: false,
      resetSent: false,

      submitting: false,
      result: null,
    };
    this.onSubmitReset = this.onSubmitReset.bind(this);
    this.onNavigateLogin = this.onNavigateLogin.bind(this);
  }

  render() {
    let shouldIndicateError = (field) => {
      let hasError = errors[field];
      let shouldShow = this.state.touched[field];

      return hasError ? shouldShow : false;
    };

    let errors = this.validateClientSide();

    let introText =
      this.state.result && this.state.result.success
        ? this.state.result.text
        : translate(
            `Enter your email and we’ll send you a password reset link.`
          );

    return (
      <div className="content-container">
        <div className="content-header">
          <h1>{introText}</h1>
        </div>

        <div className="content-main">
          <ErrorPane>{this.state.emailError}</ErrorPane>

          {!this.state.resetSent ? (
            <FormField
              className={shouldIndicateError('email') ? 'has-error' : ''}
              id="email"
              type="email"
              label={translate('Email')}
              required
              errorMessage={this.state.fieldErrors.email}
              changeAction={(event) => this.onEmailChange(event.target.value)}
              keypressAction={(event) => this.onKeyPress(event)}
              blurAction={this.handleBlur('email')}
              styles={{ marginTop: this.state.emailError ? '1rem' : '3rem' }}
            />
          ) : null}
        </div>

        <div className="content-footer">
          {!this.state.resetSent ? (
            <Button
              disabled={this.state.submitting}
              type="submit"
              appearance="primary"
              styles={{ width: '100%', marginTop: '2rem' }}
              clickAction={this.onSubmitReset}
            >
              {translate('Reset Password')}
            </Button>
          ) : null}
          <Button
            appearance="link"
            styles={{
              display: 'block',
              margin: '2rem auto 0',
              textAlign: 'center',
            }}
            clickAction={this.onNavigateLogin}
          >
            {translate('Back to Login')}
          </Button>
        </div>
      </div>
    );
  }

  onSubmitReset() {
    if (!this.validate() || this.state.submitting) {
      return;
    }

    this.setState({ submitting: true });
    this.handleSubmit();
  }

  onEmailChange(email) {
    this.setState({ email: email });
  }

  onNavigateLogin() {
    this.props.router.navigate('login', { trigger: true });
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      this.onSubmitReset();
    }
  }

  handleBlur(field) {
    let self = this;
    return function (event) {
      self.setState({
        touched: Object.assign({}, self.state.touched, { [field]: true }),
        fieldErrors: Object.assign({}, self.state.fieldErrors, {
          [field]: event.target.validationMessage,
        }),
      });
    };
  }

  handleSubmit() {
    request.post({
      url: `${window.teacher_service}/v3/passwords/forgot`,
      data: {
        email: this.state.email,
      },
      success: () => {
        this.setState({
          submitting: false,
          result: {
            text: translate(
              'Please check your email for a password reset link.'
            ),
            success: true,
          },
          resetSent: true,
          emailError: false,
        });
      },
      error: (response) => {
        let resultText =
          response.error === Constants.USER_NOT_FOUND
            ? translate('No account with that email was found.')
            : translate('An unknown error occurred. Please try again later.');
        this.setState({
          submitting: false,
          result: { text: resultText, success: false },
          emailError: resultText,
        });
      },
    });
  }

  validateClientSide() {
    let email = this.state.email;
    return {
      email: email.length === 0 || this.state.fieldErrors.email,
    };
  }

  validate() {
    let email = this.state.email;
    let isValid = false;

    if (!validations.isValidEmail(email)) {
      this.setState({ emailError: translate('Please enter a valid email.') });
    } else {
      isValid = true;
      this.setState({ emailError: false });
    }

    return isValid;
  }
}
